import SimpleSlider from 'components/plugins/SimpleSlider';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as GeneralService from '../../services/general.service';
import parse from 'html-react-parser';
import authContext from 'contexts/auth/authContext';
import { useContext } from 'react';
import { Modal, Dropdown, Checkbox, Label, Button } from 'flowbite-react';
import {
  CmsBannerLoader,
  SliderLoader,
} from 'components/interfaces/SkeletonLoader';
import ParticleSection from 'components/plugins/ParticleSection';

const bag1 = `${process.env.REACT_APP_FRONT_URL}assets/images/inner_bannerbg.jpg`,
  bag2 = `${process.env.REACT_APP_FRONT_URL}assets/images/communinty_btm_bg.jpg`,
  bag2_mob = `${process.env.REACT_APP_FRONT_URL}assets/images/about_gvfl_bg.jpg`,
  qr_code = `${process.env.REACT_APP_FRONT_URL}assets/images/qr_code.jpg`;

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

const Community = () => {
  const [width, height] = useWindowSize();
  const [banner, setBanner] = useState('');
  const [community, setCommunity] = useState('');
  const [mentors, setMentors] = useState('');
  const [portfolio, setPortfolio] = useState('');
  const [benifits, setBenifits] = useState('');
  const cntxAuth = useContext(authContext);
  const [processing, setProcessing] = useState(false);
  const [show_popup, setShowPopup] = useState(false);
  const [mentorOnModal, setMentorOnModal] = useState('');
  const [sectors_list, setSectorList] = useState('');
  const [fiterSector, setFilterSector] = useState([]);
  const [whatsappQr, setWhatsapp_Qr] = useState('');

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    setProcessing(true);
    const res = await GeneralService.GetContentAPI('get-community-page-data');
    const sector_list = await GeneralService.GetContentAPI(
      'get-dropdown-list?type=sector_list'
    );

    if (sector_list.response) {
      setSectorList(sector_list?.data);
    }

    if (res.response) {
      setBanner(res?.data?.banner_data);
      setCommunity(res?.data?.community?.cms);
      setMentors(res?.data?.mentors);
      setPortfolio(res?.data?.our_portfolio);
      setBenifits(res?.data?.benefits);
      setWhatsapp_Qr(res?.data?.whatsapp_qr);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };

  const fetchMentorDetails = (mentor) => {
    setMentorOnModal(mentor);
    setShowPopup(true);
  };

  const fetchFilteredMentor = async (idList) => {
    const res = await GeneralService.GetContentAPI(
      `members?team_category_id=2&sector_id=${idList.toString()}`
    );

    if (res.response) {
      setMentors(res?.data?.members?.data);
    }
    setFilterSector([])
  };

  const handleFilterSector = (item) => {
    let copyFilter = [...fiterSector];
    let sectorId = Object.keys(sectors_list).find(
      (key) => sectors_list[key] === item
    );

    if (copyFilter.includes(sectorId)) {
      copyFilter = copyFilter.filter((item) => item !== sectorId);
      setFilterSector(copyFilter);
    } else {
      setFilterSector([...copyFilter, sectorId]);
    }

  };
  
  return (
    <>
      {!processing && banner !== '' && banner !== null ? (
        <div
          className='bg-white h-[800px] sm:h-[700px] xl:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover'
          style={{ backgroundImage: `url(${bag1})` }}
        >
          <div className='absolute z-[1] m-auto inset-y-2/4 w-full flex items-center h-fit md:pt-[91px]'>
            <div className='container m-auto px-4'>
              <div className='sm:flex block sm:justify-between items-center'>
                <div className='md:w-6/12 w-full'>
                  {parse(
                    banner?.short_description ? banner?.short_description : ''
                  )}
                  <div className='clear-both'></div>
                  {cntxAuth.isLogged ? (
                    <></>
                  ) : (
                    <div className='float-left w-full md:pb-10 pb-5 flex flex-wrap md:flex-row flex-col items-center gap-5'>
                      <Link
                        to={`/join-community`}
                        className='clear-both bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 px-8 rounded-full inline-block text-white hover:bg-themered'
                      >
                        Join the a4X community
                      </Link>
                      <a
                        href={whatsappQr?.whatsapp_url}
                        target='_blank'
                        className='clear-both bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 px-8 rounded-full inline-block text-white hover:bg-themered '
                      >
                        Whatsapp Group
                      </a>
                    </div>
                  )}
                </div>
                <div className='md:w-4/12 w-full md:block'>
                  <img
                    className='sm:w-[300px] w-[200px] sm:m-0 m-auto'
                    src={whatsappQr?.whatsapp_qr_image_url}
                    alt={whatsappQr?.whatsapp_qr}
                  />
                  <div className='font-Quicksand_Bold text-[#535353] md:text-[17px] md:text-center text-[17px] sm:inline-block block sm:text-center text-center md:w-[250px] lg:w-[300px] w-[300px] sm:m-0 m-auto'>
                    Scan to join the a4X community WhatsApp group
                  </div>
                </div>
              </div>
              <div className='clear-both'></div>
            </div>
          </div>
        </div>
      ) : (
        <CmsBannerLoader count={1} image={bag1} />
      )}

      <div className='clear-both'></div>

      {community !== '' && community !== null && (
        <div className='bg-white float-left w-full overflow-hidden relative md:py-[100px] py-[50px]'>
          <div className='container m-auto px-4'>
            <div className='flex justify-between items-center md:flex-row flex-col gap-10'>
              <div className='lg:w-4/12 md:w-6/12 w-full'>
                <img
                  className='max-w-[400px] w-full block m-auto'
                  src={community?.image_url}
                  alt={community?.title}
                />
              </div>
              <div className='lg:w-8/12 md:w-6/12 w-full'>
                <div className='float-left w-full pb-10'>
                  <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>
                    {community?.title}
                  </h2>
                  <span className='w-[60px] h-[2px] bg-themered block'></span>
                </div>
                {parse(
                  community?.long_description ? community?.long_description : ''
                )}
              </div>
            </div>
            <div className='clear-both'></div>
          </div>
        </div>
      )}

      <div className='clear-both'></div>

      {mentors !== '' && mentors !== null && (
        <div className='bg-[#f8f9fa] float-left w-full relative md:pt-[100px] pt-[50px] pb-[80px]'>
          <div className='container m-auto px-4'>
            <div className='w-full float-left'>
              <h2 className='pb-3'>
                <span className='font-Quicksand_Bold text-black md:text-[36px] text-[26px]'>
                  Meet the Mentors
                </span>
                <div className='float-right  sm:mr-[100px] md:mr-[100px] pt-3 '>
                  {sectors_list !== '' && (
                    <Dropdown
                      inline
                      className={'z-10 h-[200px] overflow-y-auto'}
                      label='Filter by Industry'
                    >
                      <Dropdown.Header active>
                        {Object.values(sectors_list)?.map((item, index) => (
                          <div
                            key={index}
                            onClick={() => handleFilterSector(item)}
                            className={`p-2 ${
                              index === Object.values(sectors_list).length - 1
                                ? ''
                                : 'border-b border-gray-300'
                            }`}
                          >
                            <div className='flex items-center gap-2'>
                              <Checkbox
                                id='remember'
                                checked={fiterSector.includes(
                                  Object.keys(sectors_list).find(
                                    (key) => sectors_list[key] === item
                                  )
                                )}
                              />
                              <Label>{item}</Label>
                            </div>
                          </div>
                        ))}
                      </Dropdown.Header>
                      <Dropdown.Item className='cursor-default'>
                        <Button
                          className='block'
                          onClick={() => fetchFilteredMentor(fiterSector)}
                        >
                          Apply
                        </Button>
                      </Dropdown.Item>
                    </Dropdown>
                  )}
                </div>
              </h2>
              <span className='w-[60px] h-[2px] bg-themered block'></span>
              <div className='float-left w-full pt-6 pb-2 relative'>
                {mentors.length > 0 ? (
                  <SimpleSlider
                    slidesToShow={
                      Math.round(mentors.length / 2) < 3
                        ? Math.round(mentors.length / 2)
                        : 3
                    }
                    breakpoint1={3}
                    breakpoint2={2}
                    breakpoint3={1}
                    showarrows={true}
                  >
                    {Array.from({ length: mentors.length / 2 + 1 }).map(
                      (value, i, j) => {
                        i *= 2;
                        j = i + 1;
                        return (
                          <div key={i}>
                            {typeof mentors[i]?.name !== 'undefined' && (
                              <div className='float-left w-full grayscale hover:grayscale-0 flex gap-5 flex-row items-center justify-center py-2'>
                                <div className='block m-auto w-[150px] h-[150px] border-4 border-[#f8f9fa] rounded-full overflow-hidden mb-4'>
                                  <img
                                    className='w-full h-full object-cover float-left cursor-pointer'
                                    src={mentors[i]?.image_url}
                                    alt={mentors[i]?.name}
                                    onClick={() =>
                                      fetchMentorDetails(mentors[i])
                                    }
                                  />
                                </div>
                                <div
                                  className='float-left w-[calc(100%_-_150px)] cursor-pointer'
                                  onClick={() => fetchMentorDetails(mentors[i])}
                                >
                                  <h3 className='font-Quicksand_Bold text-[22px] text-[#202020]'>
                                    {mentors[i]?.name}
                                  </h3>
                                  <p className='font-WorkSans_Regular text-base text-[#535353]'>
                                    {mentors[i]?.designation}
                                  </p>
                                </div>
                              </div>
                            )}
                            {typeof mentors[j]?.name !== 'undefined' && (
                              <div className='float-left w-full grayscale hover:grayscale-0 flex gap-5 flex-row items-center justify-center py-2'>
                                <div className='block m-auto w-[150px] h-[150px] border-4 border-[#f8f9fa] rounded-full overflow-hidden mb-4'>
                                  <img
                                    className='w-full h-full object-cover float-left'
                                    src={mentors[j]?.image_url}
                                    alt={mentors[j]?.name}
                                    onClick={() =>
                                      fetchMentorDetails(mentors[j])
                                    }
                                  />
                                </div>
                                <div
                                  className='float-left w-[calc(100%_-_150px)]'
                                  onClick={() => fetchMentorDetails(mentors[j])}
                                >
                                  <h3 className='font-Quicksand_Bold text-[22px] text-[#202020]'>
                                    {mentors[j]?.name}
                                  </h3>
                                  <p className='font-WorkSans_Regular text-base text-[#535353]'>
                                    {mentors[j]?.designation}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }
                    )}
                  </SimpleSlider>
                ) : (
                  <p class='text-lg text-[#535353] font-Quicksand_Medium pb-4'>
                    Mentors Coming Soon
                  </p>
                )}
              </div>
            </div>

            <div className='clear-both'></div>
          </div>
        </div>
      )}

      <div className='clear-both'></div>

      {!processing && portfolio !== '' && portfolio !== null ? (
        <div className='bg-white float-left w-full relative md:py-[100px] py-[50px] overflow-hidden'>
          <div className='container m-auto px-4'>
            <div className='w-full float-left'>
              <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>
                Our Portfolio Startups
              </h2>
              <span className='w-[60px] h-[2px] bg-themered block'></span>
              <div className='float-left w-full pt-20 relative portfolio_slider'>
                <SimpleSlider
                  slidesToShow={portfolio.length < 4 ? portfolio.length : 4}
                  breakpoint1={3}
                  breakpoint2={2}
                  breakpoint3={1}
                  showarrows={true}
                >
                  {portfolio.map((value, index) => (
                    <div key={index}>
                      <img
                        className='m-auto slider_img'
                        src={value?.image_url}
                        alt={value?.title}
                      />
                    </div>
                  ))}
                </SimpleSlider>
              </div>
            </div>
            <div className='clear-both'></div>
          </div>
        </div>
      ) : (
        <div className='bg-white float-left w-full relative md:py-[100px] py-[50px] overflow-hidden'>
          <div className='container m-auto px-4'>
            <div className='w-full float-left'>
              <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>
                Our Portfolio Startups
              </h2>
              <span className='w-[60px] h-[2px] bg-themered block'></span>
              <div className='float-left w-full pt-20 relative'>
                <SliderLoader count={1} />
              </div>
            </div>
            <div className='clear-both'></div>
          </div>
        </div>
      )}

      <div className='clear-both'></div>

      {benifits !== '' && benifits !== null && (
        <div className='bg-[#f8f9fa] float-left w-full overflow-hidden relative md:py-[100px] py-[50px]'>
          <div className='container m-auto px-4'>
            <div className='float-left w-full text-center'>
              <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>
                What Will You Get?
              </h2>
              <span className='w-[60px] h-[2px] bg-themered block m-auto'></span>
            </div>
            <div className='float-left w-full pt-14'>
              <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-32 md:gap-20 gap-10'>
                {benifits.map((value, index) => (
                  <div
                    key={index}
                    className='float-left w-full text-center relative'
                  >
                    <div className='block m-auto w-[150px] text-center mb-4'>
                      <img
                        className='max-w-[70px] w-full object-cover block m-auto'
                        src={value?.image_url}
                        alt={value?.title}
                      />
                    </div>
                    <p className='font-WorkSans_Regular text-base text-[#535353]'>
                      {parse(value?.description ? value?.description : '')}
                    </p>
                    <span
                      className={`lg:block hidden w-[1px] float-right h-full bg-[#d9d9d9] absolute -right-16 ${
                        index < 3 ? '-bottom-16' : '-top-16'
                      }`}
                    ></span>
                    {index < 3 ? (
                      <span className='lg:block hidden w-full float-right h-[1px] bg-[#d9d9d9] absolute right-0 -bottom-16'></span>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
            <div className='clear-both'></div>
          </div>
        </div>
      )}

      <div className='clear-both'></div>

      {/* Static */}
      <div className='bg-white float-left w-full flex justify-center items-center lg:py-0 md:py-[100px] py-[50px] lg:min-h-[450px] relative bg-gradient-to-r from-[#96b2dd] to-[#d4e5f3]'>
        <div className='absolute right-0 top-0 md:w-6/12 w-full bottom-0 right_shap'>
          <ParticleSection></ParticleSection>
        </div>
        <div className='container m-auto px-4 relative z-[3] flex justify-between items-center md:flex-row flex-col gap-10'>
          <img
            className='float-right md:order-2 md:w-[305px] w-[220px]'
            src={`${process.env.REACT_APP_FRONT_URL}assets/images/community_pg_btm_img.svg`}
            alt=''
          />
          <div className='float-left'>
            <h2 className='font-Quicksand_Bold text-themeblue max-w-[520px] md:text-[36px] text-[26px] pb-3'>
              Join the a4X pool of Mentors & Experts to add value in startups
              growth journey.
            </h2>
            <p className='mb-5 font-WorkSans_Regular text-base max-w-[590px] text-[#202020]'>
              For mentors, industry veterans, ecosystem enablers and scale-up
              experts
            </p>
            {cntxAuth.isLogged ? (
              <></>
            ) : (
              <a
                href={`/join-community`}
                target='_blank'
                rel='noreferrer'
                className='bg-themeblue font-WorkSans_Medium clear-both text-base leading-10 py-1.5 px-8 rounded-full m-auto inline-block text-white hover:bg-themered'
              >
                Join a4X community
              </a>
            )}
          </div>
          <div className='clear-both'></div>
        </div>
      </div>
      <Modal
        show={show_popup}
        size='5xl'
        position={'top-right'}
        onClose={() => setShowPopup(false)}
        className='h-[100%] overflow-y-auto'
      >
        <Modal.Header />
        <Modal.Body>
          <div className='justify-content items-center  flex flex-col md:flex-row gap-8 overflow-y-auto h-auto '>
            <div className='flex-col md:flex-col mb-auto top-0 w-[45%]'>
              <img
                className=' object-cover float-left '
                src={mentorOnModal?.image_url}
                alt=''
              />
            </div>
            <div className='flex-col md:flex-col top-0 mb-auto w-[55%]  '>
              <h1 className='pl-3 text font-Quicksand_Bold text-[30px]'>
                {mentorOnModal?.name}
              </h1>
              <h2 className='pl-3 pt-5 text-base font-size-18 font-WorkSans_Regular text-[#535353] text-[22px]'>
                {mentorOnModal?.designation}
              </h2>
              <p className='pl-3  pt-5 float-left w-full font-WorkSans_Medium text-[#202020] text-base leading-7 pb-3 text-[14px] '>
                {mentorOnModal?.description
                  ? parse(mentorOnModal?.description)
                  : 'N/A'}
              </p>

              {mentorOnModal?.social_url && (
                 <a
                  target='_blank'
                  className='text-[30px] text-center pl-3 pt-4'
                  rel='noreferrer'
                  href={mentorOnModal?.social_url}
                >
                  <i className='fa-brands fa-linkedin'></i>
                </a>
              )}
              {mentorOnModal?.web_url && (
                <a
                  target='_blank'
                  className={mentorOnModal?.social_url ? 'text-[26px] text-center pl-7 pt-4':  'text-[26px] text-center pl-3 pt-4'}
                  rel='noreferrer'
                  href={mentorOnModal?.web_url}
                >
                  <i class="fa-solid fa-link"></i>
                </a>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className='clear-both'></div>
    </>
  );
};

export default Community;
