import ParticleSection from 'components/plugins/ParticleSection';
import React, { useEffect } from 'react';
import { useState } from 'react';
import StepOne from './forms/investor/StepOne';
import StepTwo from './forms/investor/StepTwo';
import StepThree from './forms/investor/StepThree';
import { Button, Modal } from 'flowbite-react';
import * as GeneralService from '../../services/general.service'
import parse from 'html-react-parser';
import { CmsBannerLoader } from 'components/interfaces/SkeletonLoader';

const bag1 = `${process.env.REACT_APP_FRONT_URL}assets/images/inner_bannerbg.jpg`;


const InvestorForm = () => {
    const [current_step, setCurrentStep] = useState(1);
    const [form_data, setFormData] = useState({});
    const [show_popup, setShowPopup] = useState(false);
    const [banner, setBanner] = useState('');
    const [popup_message, setPopupMessage] = useState('');
    const [processing, setProcessing] = useState(false);
    useEffect(() => {
        fetchContent();
    }, []);

    const setScroll = () => {
        let scroll_box = document.getElementById('scroll_box');
        scroll_box.scrollIntoView({ behavior: 'smooth' });
    }

    const updateForm = (new_data) => {
        setFormData({ ...form_data, ...new_data });
    }

    const fetchContent = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-register-page-data');
        if (res.response) {
            setBanner(res?.data?.banner_data);
            setProcessing(false);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                !processing && banner !== '' && banner !== null
                    ?
                    <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${bag1})` }}>
                        <div className='absolute z-[1] m-auto inset-y-2/4 w-full flex items-center h-fit md:pt-[91px]'>
                            <div className='container m-auto px-4'>
                                <div className='flex justify-between items-center'>
                                    <div className='md:w-6/12 w-full'>
                                        {parse(banner?.short_description ? banner?.short_description : '')}
                                    </div>
                                    <div className='md:w-6/12 w-full md:block hidden'>
                                        <img className='w-[440px] float-right' src={banner?.image_url} alt={banner?.title} />
                                    </div>
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </div>
                    </div>
                    :
                    <CmsBannerLoader count={1} image={bag1} />
            }

            <div className="clear-both"></div>

            <div className='bg-white float-left w-full overflow-hidden relative md:py-[100px] py-[50px] bg-gradient-to-l from-[#cfe3f4] to-[#f8f9fa]'>
                <div className="absolute right-0 top-0 md:w-6/12 w-full bottom-0 right_shap">
                    <ParticleSection></ParticleSection>
                </div>
                <div className='container m-auto px-4 relative z-[1]'>
                    <div className='float-left w-full pb-10'>
                        <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Investor Application</h2>
                        <p className='font-WorkSans_Regular text-base text-[#535353] leading-7 w-full max-w-[880px] pb-6'>This is a suggested format of Investor Application form for the reference. <span className='text-themered block' id='scroll_box'> *Required</span></p>
                        <span className='w-[60px] h-[2px] bg-themered block'></span>
                    </div>

                    <div className="float-left w-full ">
                        <div className="float-left w-full py-5">
                            <div className="flex sm:w-9/12 w-10/12 justify-center m-auto">
                                <div className="flex items-center relative text-center font-WorkSans_Regular pb-[70px]">
                                    <div className={`rounded-full transition duration-500 ease-in-out h-12 w-12 leading-[48px] text-lg ${(current_step >= 1) ? 'text-white bg-[#54ce51]' : 'text-[#666] bg-white'}`}>1</div>
                                    <div className="absolute top-0 -ml-10 text-center mt-16 w-32 md:text-base text-sm text-[#666]">Investor Information</div>
                                </div>
                                <div className={`flex-auto mt-6 border-t-4 transition duration-500 ease-in-out ${(current_step >= 2) ? 'border-[#54ce51]' : 'border-white'}`}></div>
                                <div className="flex items-center relative text-center font-WorkSans_Regular pb-[70px]">
                                    <div className={`rounded-full transition duration-500 ease-in-out h-12 w-12 leading-[48px] text-lg ${(current_step >= 2) ? 'text-white bg-[#54ce51]' : 'text-[#666] bg-white'}`}>2</div>
                                    <div className="absolute top-0 -ml-10 text-center mt-16 w-32 md:text-base text-sm text-[#666]">Detail About Investment</div>
                                </div>
                                <div className={`flex-auto mt-6 border-t-4 transition duration-500 ease-in-out ${(current_step >= 3) ? 'border-[#54ce51]' : 'border-white'}`}></div>
                                <div className="flex items-center relative text-center font-WorkSans_Regular pb-[70px]">
                                    <div className={`rounded-full transition duration-500 ease-in-out h-12 w-12 leading-[48px] text-lg ${(current_step >= 3) ? 'text-white bg-[#54ce51]' : 'text-[#666] bg-white'}`}>3</div>
                                    <div className="absolute top-0 -ml-10 text-center mt-16 w-32 md:text-base text-sm text-[#666]">First Time Investor</div>
                                </div>
                            </div>
                        </div>

                        <div className="float-left w-full sm:px-14 px-5 py-5 sm:py-10 bg-white rounded-xl">
                            {/* Generic Information Form-1 */}
                            {
                                (current_step === 1)
                                    ?
                                    <StepOne setCurrentStep={setCurrentStep} setScroll={setScroll} form_data={form_data} updateForm={updateForm} />
                                    :
                                    null
                            }

                            {/* Detail About Investment Form-2 */}
                            {
                                (current_step === 2)
                                    ?
                                    <StepTwo setCurrentStep={setCurrentStep} setScroll={setScroll} form_data={form_data} updateForm={updateForm} />
                                    :
                                    null
                            }

                            {/* First Time Investor Form-3 */}
                            {
                                (current_step === 3)
                                    ?
                                    <StepThree setCurrentStep={setCurrentStep} setScroll={setScroll} form_data={form_data} updateForm={updateForm} setShowPopup={setShowPopup} setPopupMessage={setPopupMessage} setFormData={setFormData} />
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

            <Modal
                show={show_popup}
                size="md"
                popup={true}
                onClick={() => setShowPopup(false)}
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {popup_message}
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={() => setShowPopup(false)} >
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
};

export default InvestorForm;