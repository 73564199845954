import React, { useEffect, useLayoutEffect } from 'react';
import { Navbar } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import * as GeneralService from '../../services/general.service'
import authContext from 'contexts/auth/authContext';
import { useContext } from 'react';

const CustNavbar = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [headerC, setHeader] = useState('');
    const cntxAuth = useContext(authContext);

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        fetchContent();
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, []);

    const isSticky = (e) => {
        const header = document.querySelector('#sticky-area-venue');
        const scrollTop = window.scrollY;
        scrollTop >= 100 ? header.classList.add('sticky') : header.classList.remove('sticky');
    };

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const fetchContent = async () => {
        const res = await GeneralService.GetContentAPI('get-header-footer-data');
        if (res.response) {
            setHeader(res?.data?.header);
        }
        else { }
    }

    return (
        <>
            <header id='sticky-area-venue' className="relative md:fixed w-full md:top-0 z-50 py-2.5 clear-both transition-all">
                <div className='xl:container container-fluid m-auto px-4'>
                    <div className='flex lg:flex-wrap justify-between items-center md:flex-col lg:flex-row'>
                        <div className="flex">
                            <Link to={`/`}><img className="w-[140px] transition-all" src={headerC?.logo} alt="" /></Link>
                        </div>
                        <Navbar fluid={true} rounded={true} className="!bg-transparent">
                            <Navbar.Toggle className='!bg-white dark:!bg-white !text-black dark:!text-black' />
                            <Navbar.Collapse className="absolute top-[100%] right-2 left-2 w-auto md:relative bg-white md:bg-transparent">
                                <Navbar.Link className='font-WorkSans_Regular !text-black hover:!text-themered lg:text-base lg:leading-[50px] md:leading-[40px] dark:focus:!bg-white md:focus:!bg-transparent focus:!bg-white md:!bg-transparent !bg-white' href="/about-us">About Us</Navbar.Link>
                                <Navbar.Link className='font-WorkSans_Regular !text-black hover:!text-themered lg:text-base lg:leading-[50px] md:leading-[40px] dark:focus:!bg-white md:focus:!bg-transparent focus:!bg-white md:!bg-transparent !bg-white' href="/investor">Investor</Navbar.Link>
                                <Navbar.Link className='font-WorkSans_Regular !text-black hover:!text-themered lg:text-base lg:leading-[50px] md:leading-[40px] dark:focus:!bg-white md:focus:!bg-transparent focus:!bg-white md:!bg-transparent !bg-white' href="/start-up">Start-up</Navbar.Link>
                                <Navbar.Link className='font-WorkSans_Regular !text-black hover:!text-themered lg:text-base lg:leading-[50px] md:leading-[40px] dark:focus:!bg-white md:focus:!bg-transparent focus:!bg-white md:!bg-transparent !bg-white' href="/community">Community</Navbar.Link>
                                <Navbar.Link className='font-WorkSans_Regular !text-black hover:!text-themered lg:text-base lg:leading-[50px] md:leading-[40px] dark:focus:!bg-white md:focus:!bg-transparent focus:!bg-white md:!bg-transparent !bg-white' href="/event-&-media">Event & Media</Navbar.Link>
                                {
                                    cntxAuth.isLogged
                                        ?
                                        <>
                                            {/* <Link className='font-WorkSans_Regular !text-white hover:!text-white lg:text-base bg-themeblue lg:leading-[50px] md:leading-[40px] hover:!bg-themered !px-6 inline-block rounded-full md:m-0 m-2' to={'/logout'}>Logout</Link> */}
                                        </>
                                        :
                                        <>
                                            {/* <Navbar.Link className='font-WorkSans_Regular !text-black hover:!text-themered lg:text-base lg:leading-[50px] md:leading-[40px] dark:focus:!bg-white md:focus:!bg-transparent focus:!bg-white md:!bg-transparent !bg-white' href="/login">Login</Navbar.Link> */}
                                            <Navbar.Link className='font-WorkSans_Regular !text-white hover:!text-white lg:text-base bg-themeblue cursor-pointer lg:leading-[50px] md:leading-[40px] hover:!bg-themered !px-6 inline-block rounded-full md:m-0 m-2' onClick={()=>window.open('https://deals.a4x.fund/', '_blank')}>Deals</Navbar.Link>
                                        </>
                                }

                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </header>
            <div className="clear-both"></div>
        </>
    )
};

export default CustNavbar;