import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import SimpleSlider from 'Plugins/SimpleSlider';
import * as GeneralService from '../../services/general.service'
import parse from 'html-react-parser';
import authContext from 'contexts/auth/authContext';
import { useContext } from 'react';
import { BannerLoader, CounterLoader, DealSliderLoader, HomeBannerLoader, SliderLoader } from 'components/interfaces/SkeletonLoader';
import ParticleSection from 'components/plugins/ParticleSection';

const bag2 = `${process.env.REACT_APP_FRONT_URL}assets/images/home_investor_bg.jpg`,
    bag2_mob = `${process.env.REACT_APP_FRONT_URL}assets/images/home_investor_bg_mob.jpg`;

const options = {
    fullScreen: {
        enable: false,
        zIndex: 0
    },
    particles: {
        number: {
            value: 120,
            density: {
                enable: true,
                value_area: 800
            }
        },
        color: {
            value: "#94c8f2"
        },
        shape: {
            type: "circle",
            stroke: {
                width: 0,
                color: "#94c8f2"
            },
            polygon: {
                nb_sides: 5
            },
            image: {
                src: "images/github.svg",
                width: 100,
                height: 100
            }
        },
        opacity: {
            value: 0.5,
            random: true,
            anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.5,
                sync: false
            }
        },
        size: {
            value: 3,
            random: true,
            anim: {
                enable: true,
                speed: 7,
                size_min: 7,
                sync: false
            }
        },
        line_linked: {
            enable: true,
            distance: 120,
            color: "#94c8f2",
            opacity: 1,
            width: 1
        },
        move: {
            enable: true,
            speed: 3,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
            }
        }
    },
    interactivity: {
        detect_on: "canvas",
        events: {
            onHover: {
                enable: true,
                mode: "grab",
                parallax: {
                    enable: false,
                    force: 5,
                    smooth: 2
                }
            },
            onClick: {
                enable: true,
                mode: "push"
            },
            resize: true
        },
        modes: {
            grab: {
                distance: 200,
                lineLinked: {
                    opacity: 1
                }
            },
            bubble: {
                distance: 400,
                size: 100,
                duration: 2,
                opacity: 1,
                speed: 2
            },
            repulse: {
                distance: 200
            },
            push: {
                particles_nb: 4
            },
            remove: {
                particles_nb: 2
            }
        }
    },
    backgroundMask: {
        enable: false,
        cover: {
            color: {
                value: {
                    r: 0,
                    g: 0,
                    b: 0
                }
            }
        }
    },
    retina_detect: true,
    fps_limit: 60,
};

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const Home = () => {
    const cntxAuth = useContext(authContext);
    const [width, height] = useWindowSize();
    const [banner, setBanner] = useState('');
    const [count, setCount] = useState('');
    const [partner, setPartner] = useState('');
    const [deals, setDeals] = useState('');
    const [portfolio, setPortfolio] = useState('');
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-home-data');
        if (res.response) {
            setBanner(res?.data?.banner_data);
            setCount(res?.data?.counts);
            setPartner(res?.data?.our_partners);
            setPortfolio(res?.data?.our_portfolio);
            setDeals(res?.data?.live_deals);
            setProcessing(false);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                !processing && banner !== '' && banner !== null
                    ?
                    <div className='bg-white lg:min-h-screen h-[500px] md:h-[700px] relative overflow-hidden'>
                        <img className='h-full w-full object-cover lg:object-center object-left float-left' src={banner?.image_url} alt="" />
                        <div className='absolute z-[1] m-auto inset-y-2/4 w-full h-full flex items-center'>
                            <div className='container m-auto px-4'>
                                {parse(banner?.short_description ? banner?.short_description : '')}
                                <h1 class="hidden lg:text-[48px] md:text-[38px] text-[28px] text-black font-Quicksand_Regular md:pb-6 pb-4 md:leading-[60px] leading-[40px]"><span class="text-themeblue font-Quicksand_Bold">A4X</span>&nbsp;Is An<br />Initiative Of&nbsp;<span class="text-themeblue font-Quicksand_Bold">GVFL</span></h1>
                                <p className='text-base font-WorkSans_Medium text-[#535353] md:max-w-[580px] max-w-[450px] pb-6 hidden'></p>
                                <a href={`https://gvfl.com/enterpreneur/`} target="_blank" className="bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 px-8 rounded-full inline-block text-white hover:bg-themered">Pitch to us</a>
                                <div className="clear-both"></div>
                            </div>
                        </div>
                    </div>
                    :
                    <HomeBannerLoader count={1} />
            }

            <div className="clear-both"></div>

            {
                !processing && count !== '' && count !== null
                    ?
                    <div className='bg-[#f8f9fa] float-left w-full overflow-hidden md:pt-[100px] pt-[50px] pb-[170px] relative bg-gradient-to-r from-[#cfe3f4] to-[#f8f9fa]'>
                        <div className="absolute left-0 top-0 md:w-6/12 w-full bottom-0 left_shap">
                            <ParticleSection id={`particals-js-2`} className={'w-full h-full'} particleOptions={options} />
                        </div>
                        <div className='container m-auto px-4 relative z-[2]'>
                            <div className='grid md:grid-cols-5 sm:grid-cols-3 grid-cols-2 gap-5 items-center'>
                                <div className='text-center font-WorkSans_Medium md:text-lg text-sm'>
                                    <span className='text-themeblue font-Quicksand_Bold md:text-[36px] text-[28px] block leading-10 md:pb-3'><CountUp end={count?.vc_funds} duration={2.75} delay={2.5} />+</span> VC <br />Funds
                                </div>
                                <div className='text-center font-WorkSans_Medium md:text-lg text-sm'>
                                    <span className='text-themeblue font-Quicksand_Bold md:text-[36px] text-[28px] block leading-10 md:pb-3'><CountUp end={count?.family_offices} duration={2.75} delay={2.5} />+</span> Family <br />Offices
                                </div>
                                <div className='text-center font-WorkSans_Medium md:text-lg text-sm'>
                                    <span className='text-themeblue font-Quicksand_Bold md:text-[36px] text-[28px] block leading-10 md:pb-3'><CountUp end={count?.angel_networks} duration={2.75} delay={2.5} />+</span> Angel <br />Networks
                                </div>
                                <div className='text-center font-WorkSans_Medium md:text-lg text-sm'>
                                    <span className='text-themeblue font-Quicksand_Bold md:text-[36px] text-[28px] block leading-10 md:pb-3'><CountUp end={count?.accelerators_incubators} duration={2.75} delay={2.5} />+</span> Accelerators <br />& Incubators
                                </div>
                                <div className='text-center font-WorkSans_Medium md:text-lg text-sm'>
                                    <span className='text-themeblue font-Quicksand_Bold md:text-[36px] text-[28px] block leading-10 md:pb-3'><CountUp end={count?.angel_investors} duration={2.75} delay={2.5} />+</span> Angel <br />Investors
                                </div>
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    :
                    <CounterLoader count={1} />
            }

            <div className="clear-both"></div>

            {
                !processing && partner !== '' && partner !== null
                    ?
                    <div className='bg-white float-left w-full relative'>
                        <div className='container m-auto px-4'>
                            <div className='w-full float-left bg-[#f8f9fa] rounded-xl px-7 py-10 -mt-[120px] shadow-[0px_0px_30px_-15px_rgba(0,0,0,0.3)]'>
                                <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Our Partners</h2>
                                <span className='w-[60px] h-[2px] bg-themered block'></span>
                                <div className='float-left w-full pt-6 pb-2 relative partner_slider'>
                                    <SimpleSlider slidesToShow={(partner.length < 5) ? partner.length : 5} breakpoint1={3} breakpoint2={2} breakpoint3={2} showarrows={true}>
                                        {
                                            partner.map((value, index) =>
                                                <div key={index}>
                                                    <img className='m-auto slider_img' src={value?.image_url} alt="" />
                                                </div>
                                            )
                                        }
                                    </SimpleSlider>
                                </div>
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    :
                    <div className='bg-white float-left w-full relative'>
                        <div className='container m-auto px-4'>
                            <div className='w-full float-left bg-[#f8f9fa] rounded-xl px-7 py-10 -mt-[120px] shadow-[0px_0px_30px_-15px_rgba(0,0,0,0.3)]'>
                                <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Our Partners</h2>
                                <span className='w-[60px] h-[2px] bg-themered block'></span>
                                <div className='float-left w-full pt-6 pb-2 relative'>
                                    <SliderLoader count={1} />
                                </div>
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>

            }

            <div className="clear-both"></div>

            {
                !processing && deals !== '' && deals !== null
                    ?
                    <div className='bg-white float-left w-full overflow-hidden relative md:py-[100px] py-[50px]'>
                        <div className='container m-auto px-4'>
                            <div className='float-left'>
                                <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Live Deals</h2>
                                <span className='w-[60px] h-[2px] bg-themered block'></span>
                            </div>
                            <Link to={`/investor`} className="bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 px-8 rounded-full float-right text-white hover:bg-themered">View More</Link>
                            <div className='float-left w-full pt-14'>
                                {
                                    deals.length > 0
                                        ?
                                        <div className='-mx-4'>
                                            <SimpleSlider slidesToShow={(deals.length < 2) ? deals.length : 2} breakpoint1={2} breakpoint2={1} breakpoint3={1}>
                                                {
                                                    deals.map((value, index) => {
                                                        let d1 = parseInt(value?.raised).toString();
                                                        let d2 = (parseInt(value?.raised) - 1).toString();
                                                        return (
                                                            <div key={index}>
                                                                <div className='w-full float-left px-4'>
                                                                    <Link to={cntxAuth.isLogged ? `/deal-detail/${value?.id}` : `/login`}>
                                                                        <div className='w-full float-left p-3 shadow-md shadow-[#e1e1e1] rounded-lg'>
                                                                            <div className='flex flex-row gap-7 items-center border-b border-[#ebebeb] pb-5'>
                                                                                <div className='w-[110px] h-[110px] border border-[#d6d6d6] rounded-xl overflow-hidden'>
                                                                                    <img src={value?.image_url} alt={value?.title} />
                                                                                </div>
                                                                                <div className='w-9/12 float-left'>
                                                                                    <h3 className='font-Quicksand_Bold md:text-[22px] text-[18px] text-[#202020] pb-3'>{value?.title}</h3>
                                                                                    <p className='font-WorkSans_Regular text-base text-[#535353]'>{value?.short_desc}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='w-full float-left py-5 font-WorkSans_Regular text-base'>
                                                                                <p className='leading-8'><img className='w-[32px] float-left leading-8 mr-1' src={value?.location_icon?.document_url} alt={value?.location} /> {value?.location}</p>
                                                                            </div>
                                                                            <div className='w-full float-left font-WorkSans_Regular text-base'>
                                                                                <div className='w-full float-left relative pb-2'>
                                                                                    <div className='w-full h-2 bg-[#f8f9fa] overflow-hidden rounded-full'>
                                                                                        <div className={`bg-[#28b82d] h-2`} style={{ width: `${d1}%` }}></div>
                                                                                    </div>
                                                                                    <span className={`bg-[#28b82d] w-[14px] h-[14px] rounded-full float-left absolute -top-[3px]`} style={{ left: `${d2}%` }}></span>
                                                                                </div>
                                                                                <p className='float-left font-WorkSans_Medium text-[#535353] text-base leading-8'>Raised {value?.raised_percentage}</p>
                                                                                <p className='float-right font-WorkSans_Medium text-[#535353] text-base leading-8'>{value?.survey_name}</p>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    )
                                                }
                                            </SimpleSlider>
                                            <div className="clear-both"></div>
                                        </div>
                                        :
                                        <p class="text-lg text-[#535353] font-Quicksand_Medium pb-4">Live Deals Coming Soon</p>
                                }

                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    :
                    <div className='bg-white float-left w-full overflow-hidden relative md:py-[100px] py-[50px]'>
                        <div className='container m-auto px-4'>
                            <div className='float-left'>
                                <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Live Deals</h2>
                                <span className='w-[60px] h-[2px] bg-themered block'></span>
                            </div>
                            <Link to={`/investor`} className="bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 px-8 rounded-full float-right text-white hover:bg-themered">View More</Link>
                            <div className='float-left w-full pt-14'>
                                <div className='-mx-4'>
                                    <DealSliderLoader count={1} />
                                    <div className="clear-both"></div>
                                </div>

                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>

            }

            <div className="clear-both"></div>

            {/* static */}
            <div className='bg-[#f8f8f9] float-left w-full relative overflow-hidden md:py-[210px] py-[50px] bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${width > 767 ? bag2 : bag2_mob})` }}>
                <div className='container m-auto px-4 relative z-[1]'>
                    <div className='flex md:flex-row flex-col md:gap-0 gap-56 justify-between items-center'>
                        <div className='float-left text-center'>
                            <h3 className='font-Quicksand_Bold lg:text-[24px] text-[18px] pb-5'>Join the Investor community to<br /> access the curated deal flow <br />of seed-stage startups.</h3>
                            {
                                cntxAuth.isLogged
                                    ?
                                    <></>
                                    :
                                    <Link  onClick={()=>window.open('https://deals.a4x.fund/', '_blank')} className="bg-themeblue font-WorkSans_Medium clear-both text-base leading-10 py-1.5 px-8 rounded-full m-auto inline-block text-white hover:bg-themered">Join as Investor</Link>
                            }
                        </div>
                        <div className='float-right text-center'>
                            <h3 className='font-Quicksand_Bold lg:text-[24px] text-[18px] pb-5'>Join the a4X pool of <br />Mentors & Experts to add value <br />in startups growth journey. </h3>
                            {
                                cntxAuth.isLogged
                                    ?
                                    <></>
                                    :
                                    <Link to={`/join-community`} className="bg-themeblue font-WorkSans_Medium clear-both text-base leading-10 py-1.5 px-8 rounded-full m-auto inline-block text-white hover:bg-themered">Join a4X community</Link>
                            }
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

            <div className="clear-both"></div>
            {
                !processing && portfolio !== '' && portfolio !== null
                    ?
                    <div className='bg-white float-left w-full relative md:pt-[100px] pt-[50px] pb-[80px] overflow-hidden'>
                        <div className='container m-auto px-4'>
                            <div className='w-full float-left'>
                                <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Our Portfolio</h2>
                                <span className='w-[60px] h-[2px] bg-themered block'></span>
                                <div className='float-left w-full pt-6 pb-2 relative portfolio_slider'>
                                    <SimpleSlider slidesToShow={(portfolio.length < 4) ? portfolio.length : 4} breakpoint1={3} breakpoint2={2} breakpoint3={1} showarrows={true}>
                                        {
                                            portfolio.map((value, index) =>
                                                <div key={index}>
                                                    <img className='m-auto slider_img' src={value?.image_url} alt={value?.title} />
                                                </div>
                                            )
                                        }
                                    </SimpleSlider>
                                </div>
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    :
                    <div className='bg-white float-left w-full relative md:pt-[100px] pt-[50px] pb-[80px] overflow-hidden'>
                        <div className='container m-auto px-4'>
                            <div className='w-full float-left'>
                                <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Our Portfolio</h2>
                                <span className='w-[60px] h-[2px] bg-themered block'></span>
                                <div className='float-left w-full pt-6 pb-2 relative'>
                                    <SliderLoader count={1} />
                                </div>
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>

            }

            <div className="clear-both"></div>

            {/* static */}
            <div className='bg-white float-left w-full flex justify-center items-center lg:py-0 md:py-[100px] py-[50px] lg:mt-[50px] lg:min-h-[500px] relative bg-gradient-to-r from-[#96b2dd] to-[#d4e5f3]'>
                <div className="absolute right-0 top-0 md:w-6/12 w-full bottom-0 right_shap">
                    <ParticleSection id={`particals-js-1`} className={'w-full h-full'} particleOptions={options} />
                </div>
                <div className='container m-auto px-4 relative z-[3] flex justify-between items-center md:flex-row flex-col gap-10'>
                    <img className='float-right md:order-2 md:w-[430px] w-[225px] z-[2]' src={`${process.env.REACT_APP_FRONT_URL}assets/images/home_btm_img.svg`} alt="" />
                    <div className='float-left'>
                        <h2 className='font-Quicksand_Bold text-themeblue max-w-[500px] md:text-[36px] text-[26px] pb-3'>Accelerate your seed-stage journey with us</h2>
                        <p className='mb-5 font-WorkSans_Regular text-base max-w-[590px] text-[#202020]'>For startups having Proof of concept or Minimum viable product <br />and are actively raising funds.</p>
                        {
                            cntxAuth.isLogged
                                ?
                                <></>
                                :
                                <a href={`https://gvfl.com/enterpreneur/`} target="_blank" className="bg-themeblue font-WorkSans_Medium clear-both text-base leading-10 py-1.5 px-8 rounded-full m-auto inline-block text-white hover:bg-themered">Pitch to us</a>
                        }
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>
        </>
    )
};

export default Home;