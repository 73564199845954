import React, { useEffect, useState } from 'react';
import * as GeneralService from '../../services/general.service'
import parse from 'html-react-parser';
import JoinCommunity from './forms/community/JoinCommunity';
import ParticleSection from 'components/plugins/ParticleSection';
import { CmsBannerLoader } from 'components/interfaces/SkeletonLoader';

const bag1 = `${process.env.REACT_APP_FRONT_URL}assets/images/inner_bannerbg.jpg`

const CommunityForm = () => {
    const [banner, setBanner] = useState('');
    const [processing, setProcessing] = useState(false);
    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-join-community-page-data');
        if (res.response) {
            setBanner(res?.data?.banner_data);
            setProcessing(false);
        }
        else { 
            setProcessing(false);
        }
    }

    return (
        <>
            {
                !processing && banner !== '' && banner !== null
                    ?
                    <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${bag1})` }}>
                        <div className='absolute z-[1] m-auto inset-y-2/4 w-full flex items-center h-fit md:pt-[91px]'>
                            <div className='container m-auto px-4'>
                                <div className='flex justify-between items-center'>
                                    <div className='md:w-6/12 w-full'>
                                        {parse(banner?.short_description ? banner?.short_description : '')}
                                    </div>
                                    <div className='md:w-6/12 w-full md:block hidden'>
                                        <img className='w-[440px] float-right' src={banner?.image_url} alt={banner?.title} />
                                    </div>
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </div>
                    </div>
                    :
                    <CmsBannerLoader count={1} image={bag1} />
            }
            <div className="clear-both"></div>

            <div className='bg-white float-left w-full overflow-hidden relative md:py-[90px] py-[50px] bg-gradient-to-l from-[#cfe3f4] to-[#f8f9fa]'>
                <div className="absolute right-0 top-0 md:w-6/12 w-full bottom-0 right_shap">
                    <ParticleSection></ParticleSection>
                </div>
                <div className='container m-auto px-4 relative z-[1]'>
                    <div className='float-left w-full pb-10'>
                        <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Community Member Application</h2>
                        <p className='font-WorkSans_Regular text-base text-[#535353] leading-7 w-full max-w-[880px] pb-6'>The name and photo associated with your Google Account will be recorded when you upload files and submit this form. Your email address is not part of your response. <span className='text-themered'> *Required</span></p>
                        <span className='w-[60px] h-[2px] bg-themered block'></span>
                    </div>

                    <div className="float-left w-full sm:px-14 px-5 py-5 sm:py-10 bg-white rounded-xl">
                        <JoinCommunity />
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

        </>
    )
};

export default CommunityForm;