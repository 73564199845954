import authContext from "contexts/auth/authContext";
import fns from "helpers/functions";
import useEffectOnce from "hooks/useEffectOnce";
import { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);

    localStorage.removeItem('a4x_token');
    localStorage.removeItem('a4x_user');
    localStorage.removeItem('a4x_profile');
    useEffect(() => {
        cntxAuth.updateLogin(false);
        navigate("/login");
        //eslint-disable-next-line
    }, []);
    useEffectOnce(() => {
        fns.TOAST_SUC("You are successfully logged out!");  
    })
    
    return (
        <></>
    );
}

export default Logout;