import { default as CustRouter } from "./routes/Router";
import { Footer, CustNavbar } from "modules/fragments";
import { Modal } from "flowbite-react";
import { Toaster } from "react-hot-toast";
import AuthState from "contexts/auth/authState";
import { useEffect, useState } from "react";

const first_popup = `${process.env.REACT_APP_FRONT_URL}assets/images/first_popup.jpg`;

export default function App() {
  const [show_popup, setShowPopup] = useState(false);

  useEffect(() => {
    const get_state = sessionStorage.getItem("pop_sh");

    const currentDate = new Date();
    const targetDate = new Date("2024-01-14"); // Set your target date here

    if (get_state === null && currentDate < targetDate) {
      setShowPopup(true);
    }

    if (get_state !== null) {
      setShowPopup(false);
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
    sessionStorage.setItem("pop_sh", "false");
  };
  return (
    <div>
      <AuthState>
        <CustNavbar />
        <Modal
          show={show_popup}
          size="xl"
          position={"top-center"}
          onClose={() => handleClose()}
          className="h-[100%] overflow-y-hidden"
        >
          <Modal.Header />
          <Modal.Body>
            <div className="justify-content items-center md:flex-row">
              <div className=" md:flex-col w-[100%]">
                <img className="object-cover" src={first_popup} alt="" />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <CustRouter />
        <Footer />
        <Toaster position="top-right" />
      </AuthState>
    </div>
  );
}
