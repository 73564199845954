import SimpleSlider from 'components/plugins/SimpleSlider';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as GeneralService from '../../services/general.service'
import parse from 'html-react-parser';
import authContext from 'contexts/auth/authContext';
import { useContext } from 'react';
import { CmsBannerLoader, DealSliderLoader, SliderLoader } from 'components/interfaces/SkeletonLoader';
import ParticleSection from 'components/plugins/ParticleSection';

const bag1 = `${process.env.REACT_APP_FRONT_URL}assets/images/inner_bannerbg.jpg`

const Investor = () => {
    const cntxAuth = useContext(authContext);
    const [banner, setBanner] = useState('');
    const [deals, setDeals] = useState('');
    const [partners, setPartners] = useState('');
    const [benifits, setBenifits] = useState('');
    const [processing, setProcessing] = useState(false);
    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-investor-page-data');
        if (res.response) {
            setBanner(res?.data?.banner_data);
            setDeals(res?.data?.live_deals);
            setPartners(res?.data?.our_investing_partners);
            setBenifits(res?.data?.benefits);
            setProcessing(false);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                !processing && banner !== '' && banner !== null
                    ?
                    <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${bag1})` }}>
                        <div className='absolute z-[1] m-auto inset-y-2/4 w-full flex items-center h-fit md:pt-[91px]'>
                            <div className='container m-auto px-4'>
                                <div className='flex justify-between items-center'>
                                    <div className='md:w-6/12 w-full'>
                                        {parse(banner?.short_description ? banner?.short_description : '')}
                                        <div className="clear-both"></div>
                                        {
                                            cntxAuth.isLogged
                                                ?
                                                <></>
                                                :
                                                <Link onClick={()=>window.open('https://deals.a4x.fund/', '_blank')} className="clear-both bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 px-8 rounded-full inline-block text-white hover:bg-themered">Join As Investor</Link>
                                        }
                                    </div>
                                    <div className='md:w-6/12 w-full md:block hidden'>
                                        <img className='w-[550px] float-right' src={banner?.image_url} alt={banner?.title} />
                                    </div>
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </div>
                    </div>
                    :
                    <CmsBannerLoader count={1} image={bag1} />
            }

            <div className="clear-both"></div>

            {
                !processing && deals !== '' && deals !== null
                    ?
                    <div className='bg-white float-left w-full overflow-hidden relative md:py-[100px] py-[50px]'>
                        <div className='container m-auto px-4'>
                            <div className='float-left w-full'>
                                <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Active Deals</h2>
                                <span className='w-[60px] h-[2px] bg-themered block'></span>
                            </div>
                            <div className='float-left w-full pt-14 relative'>
                                {
                                    deals.length > 0
                                    ?
                                    <div className='-mx-4'>
                                        <SimpleSlider slidesToShow={(deals.length < 3) ? deals.length : 3} breakpoint1={2} breakpoint2={1} breakpoint3={1} showarrows={true}>
                                            {
                                                deals.map((value, index) => {
                                                    let d1 = parseInt(value?.raised).toString();
                                                    let d2 = (parseInt(value?.raised) - 1).toString();

                                                    return (
                                                        <div key={index}>
                                                            <div className='w-full float-left px-4'>
                                                                <Link to={cntxAuth.isLogged ? `/deal-detail/${value?.id}` : `/login`}>
                                                                    <div className='w-full float-left p-3 shadow-md shadow-[#e1e1e1] rounded-lg bg-[#f8f9fa]'>
                                                                        <div className='flex flex-col gap-5 items-center border-b border-[#ebebeb] pb-5'>
                                                                            <div className='w-[75px] h-[75px] border border-[#d6d6d6] rounded-xl overflow-hidden'>
                                                                                <img src={value?.image_url} alt={value?.title} />
                                                                            </div>
                                                                            <div className='w-full text-center float-left'>
                                                                                <h3 className='font-Quicksand_Bold md:text-[22px] text-[18px] text-[#202020] pb-3'>{value?.title}</h3>
                                                                                <p className='font-WorkSans_Regular text-base text-[#535353] min-h-[48px]'>{value?.short_desc}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-full float-left py-5 font-WorkSans_Regular text-base'>
                                                                            <p className='leading-8'><img className='w-[32px] float-left leading-8 mr-1' src={value?.location_icon?.document_url} alt={value?.location} /> {value?.location}</p>
                                                                        </div>
                                                                        <div className='w-full float-left font-WorkSans_Regular text-base'>
                                                                            <div className='w-full float-left relative pb-2'>
                                                                                <div className='w-full h-2 bg-[#f8f9fa] overflow-hidden rounded-full'>
                                                                                    <div className={`bg-[#28b82d] h-2`} style={{ width: `${d1}%` }}></div>
                                                                                </div>
                                                                                <span className={`bg-[#28b82d] w-[14px] h-[14px] rounded-full float-left absolute -top-[3px]`} style={{ left: `${d2}%` }}></span>
                                                                            </div>
                                                                            <p className='float-left font-WorkSans_Medium text-[#535353] text-base leading-8'>Raised {value?.raised_percentage}</p>
                                                                            <p className='float-right font-WorkSans_Medium text-[#535353] text-base leading-8'>{value?.survey_name}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                )
                                            }
                                        </SimpleSlider>
                                        <div className="clear-both"></div>

                                    </div>
                                    :
                                    <p class="text-lg text-[#535353] font-Quicksand_Medium pb-4">Active Deals Coming Soon</p>
                                }

                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    :
                    <div className='bg-white float-left w-full overflow-hidden relative md:py-[100px] py-[50px]'>
                        <div className='container m-auto px-4'>
                            <div className='float-left w-full'>
                                <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Active Deals</h2>
                                <span className='w-[60px] h-[2px] bg-themered block'></span>
                            </div>
                            <div className='float-left w-full pt-14 relative'>
                                <div className='-mx-4'>
                                    <DealSliderLoader count={1} />
                                    <div className="clear-both"></div>
                                </div>

                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
            }

            <div className="clear-both"></div>

            {
                !processing && partners !== '' && partners !== null
                    ?
                    <div className='bg-[#f8f9fa] float-left w-full overflow-hidden relative md:py-[100px] py-[50px]'>
                        <div className='container m-auto px-4'>
                            <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Our Investing Partners</h2>
                            <span className='w-[60px] h-[2px] bg-themered block'></span>
                            <div className='float-left w-full md:pt-20 pt-10 pb-2 relative portfolio_slider'>
                                <SimpleSlider slidesToShow={(partners.length < 5) ? partners.length : 5} breakpoint1={3} breakpoint2={2} breakpoint3={1} showarrows={true}>
                                    {
                                        partners.map((value, index) =>
                                            <div key={index}>
                                                <img className='m-auto slider_img' src={value?.image_url} alt={value?.title} />
                                            </div>
                                        )
                                    }
                                </SimpleSlider>
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    :
                    <div className='bg-[#f8f9fa] float-left w-full overflow-hidden relative md:py-[100px] py-[50px]'>
                        <div className='container m-auto px-4'>
                            <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Our Investing Partners</h2>
                            <span className='w-[60px] h-[2px] bg-themered block'></span>
                            <div className='float-left w-full md:pt-20 pt-10 pb-2 relative'>
                                <SliderLoader count={1} />
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>

            }

            <div className="clear-both"></div>

            {
                benifits !== '' && benifits !== null
                &&
                <div className='bg-white float-left w-full overflow-hidden relative md:py-[100px] py-[50px]'>
                    <div className='container m-auto px-4'>
                        <div className='float-left w-full text-center'>
                            <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Why Invest In Startups ?</h2>
                            <p className='text-lg text-[#535353] font-Quicksand_Medium pb-4'>Benefits Of Joining The Investment Community</p>
                            <span className='w-[60px] h-[2px] bg-themered block m-auto'></span>
                        </div>
                        <div className='float-left w-full pt-14'>
                            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-32 md:gap-20 gap-10'>
                                {
                                    benifits.map((value, index) =>
                                        <div key={index} className='float-left w-full text-center relative'>
                                            <div className='block m-auto w-[150px] text-center mb-4'>
                                                <img className='max-w-[70px] w-full object-cover block m-auto' src={value?.image_url} alt={value?.title} />
                                            </div>
                                            <p className='font-WorkSans_Regular text-base text-[#535353]'>{parse(value?.description ? value?.description : '')}</p>
                                            <span className={`lg:block hidden w-[1px] float-right h-full bg-[#d9d9d9] absolute -right-16 ${index < 3 ? '-bottom-16' : '-top-16'}`}></span>
                                            {
                                                index < 3
                                                    ?
                                                    <span className='lg:block hidden w-full float-right h-[1px] bg-[#d9d9d9] absolute right-0 -bottom-16'></span>
                                                    :
                                                    null
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="clear-both"></div>
                    </div>
                </div>
            }

            <div className="clear-both"></div>

            {/* Static */}
            <div className='bg-white float-left w-full flex justify-center items-center lg:py-0 md:py-[100px] py-[50px] lg:min-h-[450px] relative bg-gradient-to-r from-[#96b2dd] to-[#d4e5f3]'>
                <div className="absolute right-0 top-0 md:w-6/12 w-full bottom-0 right_shap">
                    <ParticleSection></ParticleSection>
                </div>
                <div className='container m-auto px-4 relative z-[3] flex justify-between items-center md:flex-row flex-col gap-10'>
                    <img className='float-right md:order-2 md:w-[422px] w-[322px]' src={`${process.env.REACT_APP_FRONT_URL}assets/images/inverter_pg_btm_img.svg`} alt="" />
                    <div className='float-left'>
                        <h2 className='font-Quicksand_Bold text-themeblue md:text-[36px] text-[26px] pb-3'>Join the a4X <br />ecosystem of investors</h2>
                        <p className='mb-5 font-WorkSans_Regular text-base max-w-[590px] text-[#202020]'>For Angel investors/ Family offices/ HNIs looking at investing <br/>in seed stage startups.</p>
                        {
                            cntxAuth.isLogged
                                ?
                                <></>
                                :
                                <Link  onClick={()=>window.open('https://deals.a4x.fund/', '_blank')} className="bg-themeblue font-WorkSans_Medium clear-both text-base leading-10 py-1.5 px-8 rounded-full m-auto inline-block text-white hover:bg-themered">Join As Investor</Link>
                        }
                    </div >
                    <div className="clear-both"></div>
                </div >
            </div >
        </>
    )
};

export default Investor;