import SimpleSlider from 'components/plugins/SimpleSlider';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as GeneralService from '../../services/general.service'
import parse from 'html-react-parser';
import authContext from 'contexts/auth/authContext';
import { useContext } from 'react';
import { CmsBannerLoader, SliderLoader } from 'components/interfaces/SkeletonLoader';
import ParticleSection from 'components/plugins/ParticleSection';

const bag1 = `${process.env.REACT_APP_FRONT_URL}assets/images/inner_bannerbg.jpg`

const StartUp = () => {
    const cntxAuth = useContext(authContext);
    const [banner, setBanner] = useState('');
    const [benifits, setBenifits] = useState('');
    const [portfolio, setPortfolio] = useState('');
    const [processing, setProcessing] = useState(false);
    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-startup-page-data');
        if (res.response) {
            setBanner(res?.data?.banner_data);
            setPortfolio(res?.data?.our_portfolio_startups);
            setBenifits(res?.data?.benefits);
            setProcessing(false);
        }
        else {
            setProcessing(false);
        }
    }

    const startup_counts = async () => {
        const res = await GeneralService.PostForm('track-no-of-startup', {track_numbers:"1"});
        if (res.response) { }
    }

    return (
        <>
            {
                !processing && banner !== '' && banner !== null
                    ?
                    <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${bag1})` }}>
                        <div className='absolute z-[1] m-auto inset-y-2/4 w-full flex items-center h-fit md:pt-[91px]'>
                            <div className='container m-auto px-4'>
                                <div className='flex justify-between items-center'>
                                    <div className='md:w-6/12 w-full'>
                                        {parse(banner?.short_description ? banner?.short_description : '')}
                                        <div className="clear-both"></div>
                                        {
                                            cntxAuth.isLogged
                                                ?
                                                <></>
                                                :
                                                <a href={`https://gvfl.com/enterpreneur/`} onClick={startup_counts} target="_blank" className="clear-both bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 px-8 rounded-full inline-block text-white hover:bg-themered">Pitch to us</a>
                                        }
                                    </div>
                                    <div className='md:w-6/12 w-full md:block hidden'>
                                        <img className='w-[560px] float-right' src={banner?.image_url} alt={banner?.title} />
                                    </div>
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </div>
                    </div>
                    :
                    <CmsBannerLoader count={1} image={bag1} />
            }

            <div className="clear-both"></div>

            {
                benifits !== '' && benifits !== null
                &&
                <div className='bg-white float-left w-full overflow-hidden relative md:py-[100px] py-[50px]'>
                    <div className='container m-auto px-4'>
                        <div className='float-left w-full text-center'>
                            <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Benefits of Fundraising Through A4X</h2>
                            <span className='w-[60px] h-[2px] bg-themered block m-auto'></span>
                        </div>
                        <div className='float-left w-full pt-14'>
                            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-32 md:gap-20 gap-10'>
                                {
                                    benifits.map((value, index) =>
                                        <div key={index} className='float-left w-full text-center relative'>
                                            <div className='block m-auto w-[150px] text-center mb-4'>
                                                <img className='max-w-[70px] w-full object-cover block m-auto' src={value?.image_url} alt={value?.title} />
                                            </div>
                                            <p className='font-WorkSans_Regular text-base text-[#535353]'>{parse(value?.description ? value?.description : '')}</p>
                                            <span className={`lg:block hidden w-[1px] float-right h-full bg-[#d9d9d9] absolute -right-16 ${index < 3 ? '-bottom-16' : '-top-16'}`}></span>
                                            {
                                                index < 3
                                                    ?
                                                    <span className='lg:block hidden w-full float-right h-[1px] bg-[#d9d9d9] absolute right-0 -bottom-16'></span>
                                                    :
                                                    null
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="clear-both"></div>
                    </div>
                </div>
            }

            <div className="clear-both"></div>

            {/* Static */}
            <div className='bg-white float-left w-full flex justify-center items-center lg:py-0 md:py-[100px] py-[50px] lg:mt-[64px] lg:min-h-[520px] relative bg-gradient-to-r from-[#96b2dd] to-[#d4e5f3]'>
                <div className="absolute right-0 top-0 md:w-6/12 w-full bottom-0 right_shap">
                    <ParticleSection></ParticleSection>
                </div>
                <div className='container m-auto px-4 relative z-[3] flex justify-between items-center md:flex-row flex-col gap-10'>
                    <img className='float-right md:order-2 md:w-[500px] w-[330px]' src={`${process.env.REACT_APP_FRONT_URL}assets/images/startup_pg_btm_img.svg`} alt="" />
                    <div className='float-left'>
                        <h2 className='font-Quicksand_Bold text-themeblue max-w-[500px] md:text-[36px] text-[26px] pb-3'>Accelerate your seed-stage journey with us</h2>
                        <p className='mb-5 font-WorkSans_Regular text-base max-w-[590px] text-[#202020]'>For startups having Proof of concept or Minimum viable product <br />and are actively raising funds.</p>
                        {
                            cntxAuth.isLogged
                                ?
                                <></>
                                :
                                <a href={`https://gvfl.com/enterpreneur/`} onClick={startup_counts} target="_blank" className="bg-themeblue font-WorkSans_Medium clear-both text-base leading-10 py-1.5 px-8 rounded-full m-auto inline-block text-white hover:bg-themered">Pitch to us</a>
                        }
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

            <div className="clear-both"></div>

            {
                !processing && portfolio !== '' && portfolio !== null
                    ?
                    <div className='bg-white float-left w-full relative md:py-[100px] py-[50px] overflow-hidden'>
                        <div className='container m-auto px-4'>
                            <div className='w-full float-left'>
                                <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Our Portfolio Startups</h2>
                                <span className='w-[60px] h-[2px] bg-themered block'></span>
                                <div className='float-left w-full pt-20 relative portfolio_slider'>
                                    <SimpleSlider slidesToShow={(portfolio.length < 4) ? portfolio.length : 4} breakpoint1={3} breakpoint2={2} breakpoint3={1} showarrows={true}>
                                        {
                                            portfolio.map((value, index) =>
                                                <div key={index}>
                                                    <img className='m-auto slider_img' src={value?.image_url} alt={value?.title} />
                                                </div>
                                            )
                                        }
                                    </SimpleSlider>
                                </div>
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                    :
                    <div className='bg-white float-left w-full relative md:py-[100px] py-[50px] overflow-hidden'>
                        <div className='container m-auto px-4'>
                            <div className='w-full float-left'>
                                <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Our Portfolio Startups</h2>
                                <span className='w-[60px] h-[2px] bg-themered block'></span>
                                <div className='float-left w-full pt-20 relative'>
                                    <SliderLoader count={1} />
                                </div>
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
            }
        </>
    )
};

export default StartUp;