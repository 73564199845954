import ParticleSection from 'components/plugins/ParticleSection';
import SimpleSlider from 'components/plugins/SimpleSlider';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const home_banner = `${process.env.REACT_APP_FRONT_URL}assets/images/home_banner.jpg`;
const home_counter = `${process.env.REACT_APP_FRONT_URL}assets/images/home_counter_bg.jpg`;

const colors = `baseColor="#ccc" highlightColor="#bbb"`;
export const BlockLoader = ({ count, width, height }) => {
    return (
        <Skeleton
            count={count}
            style={{
                padding: '0.5rem',
                marginBottom: '0.4rem',
                width: width,
                height: height,
            }}
        />
    )
}

export const BlogListLoader = ({ count }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <div key={index} className='float-left w-full p-2.5 hover:bg-[#f8f9fa]'>
                    <div className='float-left w-full h-[230px] overflow-hidden mb-4 relative'>
                        <Skeleton height={230} />
                    </div>
                    <Skeleton width={120} />
                    <Skeleton />
                    <Skeleton width={300} />
                    <Skeleton count={7} />
                </div>
            ))
        )
    }
}

export const HomeBannerLoader = ({ count, image }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <>
                    <div key={index} className='bg-white lg:min-h-screen h-[500px] md:h-[700px] relative overflow-hidden'>
                        <div className='absolute z-[1] m-auto inset-y-2/4 w-full h-full flex items-center'>
                            <div className='container m-auto px-4'>
                                <Skeleton width={300} height={30} baseColor="#ddd" highlightColor="#eee" />
                                <Skeleton width={300} baseColor="#ddd" highlightColor="#eee" />
                                <Skeleton width={300} baseColor="#ddd" highlightColor="#eee" />
                                <Skeleton width={250} baseColor="#ddd" highlightColor="#eee" />
                                <br />
                                <Skeleton width={150} height={40} borderRadius={50} baseColor="#ddd" highlightColor="#eee" />
                                <div className="clear-both"></div>
                            </div>
                        </div>
                        <img className='h-full w-full object-cover lg:object-center object-left float-left' src={home_banner} alt="" />
                    </div>
                </>
            ))
        )
    }
}

export const CmsBannerLoader = ({ count, image}) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <>
                    <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${image})` }}>
                        <div className='absolute z-[1] m-auto inset-y-2/4 w-full flex items-center h-fit md:pt-[91px]'>
                            <div className='container m-auto px-4'>
                                <div className='flex justify-between items-center'>
                                    <div className='lg:w-auto md:w-6/12 w-full'>
                                        <Skeleton width={300} height={30} baseColor="#ddd" highlightColor="#eee" />
                                        <Skeleton width={300} baseColor="#ddd" highlightColor="#eee" />
                                        <Skeleton width={300} baseColor="#ddd" highlightColor="#eee" />
                                        <Skeleton width={250} baseColor="#ddd" highlightColor="#eee" />
                                        <br />
                                        <Skeleton width={150} height={40} borderRadius={50} baseColor="#ddd" highlightColor="#eee" />
                                        <div className="clear-both"></div>
                                    </div>
                                    <div className='lg:w-auto md:w-6/12 w-full md:block hidden'>
                                    <Skeleton width={500} height={300} borderRadius={50} baseColor="#ddd" highlightColor="#eee" />
                                    </div>
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </div>
                    </div>
                </>
            ))
        )
    }
}

export const CmsContentLoader = ({ count, image}) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <>
                    <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${image})` }}>
                        <div className='absolute z-[1] m-auto inset-y-2/4 w-full flex items-center h-fit md:pt-[91px]'>
                            <div className='container m-auto px-4'>
                                <div className='flex justify-between items-center'>
                                    <div className='lg:w-auto md:w-6/12 w-full'>
                                        <Skeleton width={300} height={30} baseColor="#ddd" highlightColor="#eee" />
                                        <Skeleton width={300} baseColor="#ddd" highlightColor="#eee" />
                                        <Skeleton width={300} baseColor="#ddd" highlightColor="#eee" />
                                        <Skeleton width={250} baseColor="#ddd" highlightColor="#eee" />
                                        <br />
                                        <Skeleton width={150} height={40} borderRadius={50} baseColor="#ddd" highlightColor="#eee" />
                                        <div className="clear-both"></div>
                                    </div>
                                    <div className='lg:w-auto md:w-6/12 w-full md:block hidden'>
                                    <Skeleton width={500} height={300} borderRadius={50} baseColor="#ddd" highlightColor="#eee" />
                                    </div>
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </div>
                    </div>
                </>
            ))
        )
    }
}

export const CounterLoader = ({ count }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <>
                    <div className='bg-[#f8f9fa] float-left w-full overflow-hidden md:pt-[100px] pt-[50px] pb-[170px] relative bg-gradient-to-r from-[#cfe3f4] to-[#f8f9fa]'>
                        <div className="absolute left-0 top-0 w-6/12 bottom-0 left_shap">
                            <ParticleSection></ParticleSection>
                        </div>
                        <div className='container m-auto px-4 relative z-[2]'>
                            <div className='grid md:grid-cols-5 sm:grid-cols-3 grid-cols-2 gap-5 items-center'>
                                <div className='text-center font-WorkSans_Medium md:text-lg text-sm'>
                                    <Skeleton width={50} height={50} />
                                    <Skeleton width={100} />
                                    <Skeleton width={200} />
                                </div>
                                <div className='text-center font-WorkSans_Medium md:text-lg text-sm'>
                                    <Skeleton width={50} height={50} />
                                    <Skeleton width={100} />
                                    <Skeleton width={200} />
                                </div>
                                <div className='text-center font-WorkSans_Medium md:text-lg text-sm'>
                                    <Skeleton width={50} height={50} />
                                    <Skeleton width={100} />
                                    <Skeleton width={200} />
                                </div>
                                <div className='text-center font-WorkSans_Medium md:text-lg text-sm'>
                                    <Skeleton width={50} height={50} />
                                    <Skeleton width={100} />
                                    <Skeleton width={200} />
                                </div>
                                <div className='text-center font-WorkSans_Medium md:text-lg text-sm'>
                                    <Skeleton width={50} height={50} />
                                    <Skeleton width={100} />
                                    <Skeleton width={200} />
                                </div>
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    </div>
                </>
            ))
        )
    }
}

export const SliderLoader = ({ count }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <>
                    <SimpleSlider key={index} slidesToShow={4} breakpoint1={3} breakpoint2={2} breakpoint3={1}>
                        <div>
                            <Skeleton width={200} height={80} />
                        </div>
                        <div>
                            <Skeleton width={200} height={80} />
                        </div>
                        <div>
                            <Skeleton width={200} height={80} />
                        </div>
                        <div>
                            <Skeleton width={200} height={80} />
                        </div>
                        <div>
                            <Skeleton width={200} height={80} />
                        </div>
                    </SimpleSlider>
                </>
            ))
        )
    }
}

export const DealSliderLoader = ({ count }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <>
                    <SimpleSlider key={index} slidesToShow={2} breakpoint1={2} breakpoint2={1} breakpoint3={1}>
                        <div>
                            <Skeleton width={600} height={250} />
                        </div>
                        <div>
                            <Skeleton width={600} height={250} />
                        </div>
                        <div>
                            <Skeleton width={600} height={250} />
                        </div>
                    </SimpleSlider>
                </>
            ))
        )
    }
}