import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home, AboutUs, Investor, StartUp, Community, Event, LogIn, Logout, ResetPassword, CommunityForm, InvestorForm, DealDetails, EventDetail, CmsPage } from 'MdlPages';
import { PageNotFound } from 'ErrorPages';

import authContext from 'contexts/auth/authContext';

/** Middleware to check if user is not logged in */
const PrivateRoute = ({ children }) => {
    const cntxAuth = useContext(authContext);
    return cntxAuth.isLogged ? children : <Navigate to="/login" />;
}

/** Middleware to check if user is logged in */
const LoggedRoute = ({ children }) => {
    const cntxAuth = useContext(authContext);
    return cntxAuth.isLogged ? <Navigate to="/login" /> : children;
}

const Router = () => {
    return (
        <>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about-us" element={<AboutUs />} />
                <Route exact path="/investor" element={<Investor />} />
                <Route exact path="/start-up" element={<StartUp />} />
                <Route exact path="/community" element={<Community />} />
                <Route exact path="/event-&-media" element={<Event />} />
                <Route exact path="/join-community" element={<CommunityForm />} />
                {/* <Route exact path="/join-investor" element={<LoggedRoute><InvestorForm /></LoggedRoute>} /> */}
                <Route exact path="/reset-password/:id" element={<LoggedRoute><ResetPassword /></LoggedRoute>} />
                <Route exact path="/deal-detail/:id" element={<PrivateRoute><DealDetails /></PrivateRoute>} />
                <Route exact path="/event-detail/:type/:url" element={<EventDetail />} />
                <Route exact path="/page/:slug" element={<CmsPage />} />
                {/* AUTH ROUTES */}
                {/* <Route exact path="/login" element={<LogIn />} /> */}
                <Route exact path="/logout" element={<Logout />} />
                {/* ERROR ROUTES */}
                <Route path="/404" element={<PageNotFound />} />
                <Route path="*" element={<Navigate replace to="/404" />} />
                {/* ERROR ROUTES */}
            </Routes>
        </>
    )
};

export default Router;