import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as GeneralService from '../../services/general.service'
import parse from 'html-react-parser';

const bag1 = `${process.env.REACT_APP_FRONT_URL}assets/images/inner_bannerbg.jpg`,
    bag2 = `${process.env.REACT_APP_FRONT_URL}assets/images/investor_bg.jpg`

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const CmsPage = () => {
    const [content, setContent] = useState('');
    const { slug } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        fetchContent();
    }, [slug]);

    const fetchContent = async () => {
        const res = await GeneralService.GetContentAPI(`get-cms/${slug}`);
        if (res.response) {
            console.log(res);
            if(res?.data === null || res?.data === ''){
                navigate('/404')
            }
            setContent(res?.data);
        }
        else { }
    }

    return (
        <>
            <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${bag1})` }}>

            </div>
            <div className="clear-both"></div>

            <div className='bg-white float-left w-full relative md:pb-[100px] pb-[50px] bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${bag2})` }}>
                <div className='container m-auto px-4'>
                    <div className='float-left w-full -mt-[350px] shadow-lg bg-white px-6 py-5'>
                        <div className="float-left w-full pb-10">
                            <h2 className="font-Quicksand_Bold text-black md:text-[26px] text-[20px] pb-3">{content?.title}</h2>
                            <span className="w-[60px] h-[2px] bg-themered block"></span>
                        </div>
                        <div className="float-left w-full blog_detail">
                            {parse(content?.long_description ? content?.long_description : '')}
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

        </>
    )
};

export default CmsPage;