import ParticleSection from 'components/plugins/ParticleSection';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as GeneralService from '../../services/general.service'
import parse from 'html-react-parser';
import { Pagination } from 'components/plugins';
import { BlogListLoader } from 'components/interfaces/SkeletonLoader';
import authContext from 'contexts/auth/authContext';
import { useContext } from 'react';
import { CmsBannerLoader, SliderLoader } from 'components/interfaces/SkeletonLoader';

const bag1 = `${process.env.REACT_APP_FRONT_URL}assets/images/inner_bannerbg.jpg`;

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const Event = () => {
    const cntxAuth = useContext(authContext);
    const [width, height] = useWindowSize();
    const [banner, setBanner] = useState('');
    const [pitch, setPitch] = useState('');
    const [blogs, setBlogs] = useState('');
    const [events, setEvents] = useState('');
    const [processing, setProcessing] = useState(false);

    const [showing, setShowing] = useState('blogs');
    const [current_page, setCurrentPage] = useState(0);
    const [last_page, setLastPage] = useState(0);
    const [total_count, setTotalCount] = useState(0);
    const length = 6;

    useEffect(() => {
        fetchContent();
        if (showing === 'blogs') {
            fetchBlogs();
        } else {
            fetchEvents();
        }
    }, [showing]);

    const fetchContent = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-event-media-page-data');
        if (res.response) {
            setBanner(res?.data?.banner_data);
            setPitch(res?.data?.upcoming_pitch_call);
            setProcessing(false);
        }
        else {
            setProcessing(false);
        }
    }
    const fetchBlogs = async (page = 1) => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI(`get-blogs?is_pagination=1&page=${page}&length=${length}`);
        if (res.response) {
            setBlogs(res?.data?.blogs?.data);
            setCurrentPage(res?.data?.blogs?.current_page);
            setLastPage(res?.data?.blogs?.last_page);
            setTotalCount(res?.data?.blogs?.total);
            setProcessing(false);
        }
        else { setProcessing(false); }
    }

    const fetchEvents = async (page = 1) => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI(`pitch-calls?is_pagination=1&page=${page}&length=${length}`);
        if (res.response) {
            setEvents(res?.data?.pitch_calls?.data);
            setCurrentPage(res?.data?.pitch_calls?.current_page);
            setLastPage(res?.data?.pitch_calls?.last_page);
            setTotalCount(res?.data?.pitch_calls?.total);
            setProcessing(false);
        }
        else { setProcessing(false); }
    }
    const setScroll = () => {
        let scroll_box = document.getElementById('scroll_box');
        scroll_box.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <>
            {
                processing && banner !== '' && banner !== null
                    ?
                    <CmsBannerLoader count={1} image={bag1} />
                    :
                    <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${bag1})` }}>
                        <div className='absolute z-[1] m-auto inset-y-2/4 w-full flex items-center h-fit md:pt-[91px]'>
                            <div className='container m-auto px-4'>
                                <div className='flex justify-between items-center'>
                                    <div className='md:w-6/12 w-full'>
                                        {parse(banner?.short_description ? banner?.short_description : '')}
                                        <div className="clear-both"></div>
                                        {
                                            cntxAuth.isLogged
                                                ?
                                                <></>
                                                :
                                                <Link  onClick={()=>window.open('https://deals.a4x.fund/', '_blank')} className="clear-both bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 px-8 rounded-full inline-block text-white hover:bg-themered">Register as investor</Link>
                                        }
                                    </div>
                                    <div className='md:w-6/12 w-full md:block hidden'>
                                        <img className='w-[440px] float-right' src={banner?.image_url} alt={banner?.title} />
                                    </div>
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </div>
                    </div>

            }

            <div className="clear-both"></div>

            {
                pitch !== '' && pitch !== null
                &&
                <div className='bg-white float-left w-full overflow-hidden relative md:py-[100px] py-[50px] bg-gradient-to-r from-[#cfe3f4] to-[#f8f9fa]'>
                    <div className="absolute left-0 top-0 md:w-6/12 w-full bottom-0 left_shap">
                        <ParticleSection></ParticleSection>
                    </div>
                    <div className='container m-auto px-4 relative z-[1]'>
                        <div className='flex justify-between items-center md:flex-row flex-col gap-5'>
                            <div className='lg:w-5/12 md:w-6/12 w-full'>
                                <img className='max-w-[480px] w-full block m-auto' src={pitch?.image_url} alt="" />
                            </div>
                            <div className='lg:w-7/12 md:w-6/12 w-full'>
                                <div className='w-full float-left pb-4'>
                                    <p className='float-left py-1.5 px-3 bg-themered text-white font-WorkSans_Regular text-base'>Upcoming</p>
                                    <p className='float-left py-1.5 px-3 bg-themeblue text-white font-WorkSans_Regular text-base ml-3'>{pitch?.formatted_date}</p>
                                </div>
                                <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>{pitch?.title}</h2>
                                {parse(pitch?.description ? pitch?.description : '')}
                                {
                                    cntxAuth.isLogged
                                        ?
                                        <></>
                                        :
                                        <Link to={'/join-investor'} className="clear-both bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 px-8 rounded-full inline-block text-white hover:bg-themered">Register</Link>
                                }
                            </div>
                        </div>
                        <div className="clear-both"></div>
                    </div>
                </div>
            }

            <div className="clear-both" id='scroll_box'></div>

            <div className='bg-white float-left w-full overflow-hidden relative md:py-[100px] py-[50px]'>
                <div className='container m-auto px-4'>
                    <div className='float-left w-full md:pb-10 pb-5 flex md:flex-row flex-col md:justify-between items-center md:gap-0 gap-5'>
                        <div className='float-left'>
                            <h2 className='font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3'>Pitching sessions & events</h2>
                            <span className='w-[60px] h-[2px] bg-themered block'></span>
                        </div>
                        <ul className='float-right flex flex-row gap-10'>
                            <li onClick={() => { setShowing('events') }} className={`float-left text-xl font-WorkSans_Medium border-b py-3 ${(showing === 'events') ? 'border-themered text-themered' : 'border-transparent text-[#535353] cursor-pointer'}`}>Events</li>
                            <li onClick={() => { setShowing('blogs') }} className={`float-left text-xl font-WorkSans_Medium border-b py-3 ${(showing === 'blogs') ? 'border-themered text-themered' : 'border-transparent text-[#535353] cursor-pointer'}`}>Blogs</li>
                        </ul>
                    </div>
                    <div className="clear-both"></div>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5'>
                        {
                            processing
                                ?
                                <BlogListLoader count={3} />
                                :
                                <>
                                    {
                                        showing === 'blogs'
                                            ?
                                            <>
                                                {
                                                    blogs !== '' && blogs !== null && blogs.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                blogs.map((value, index) =>
                                                                    <div key={index} className='float-left w-full p-2.5 hover:bg-[#f8f9fa]'>
                                                                        <div className='float-left w-full h-[230px] overflow-hidden mb-4 relative'>
                                                                            <img className='w-full h-full object-cover float-left' src={value?.image_url} alt="" />
                                                                            <span className='bg-themered text-white text-[12px] px-3 py-1 absolute left-0 top-0 z-10'>Blog</span>
                                                                        </div>
                                                                        <p className='font-WorkSans_Regular text-sm text-[#535353] pb-2'>{value?.date}</p>
                                                                        <h3 className='font-Quicksand_Bold md:text-[20px] text-[16px] text-[#202020] pb-3 hover:text-[#0059a9]'>
                                                                            <Link to={`/event-detail/blog/${value?.url}`}>{value?.title}</Link>
                                                                        </h3>
                                                                        <p className='font-WorkSans_Regular text-base text-[#535353]'>{value?.long_desc}</p>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <p class="text-lg text-[#535353] font-Quicksand_Medium pb-4">Blogs Coming Soon</p>
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    events !== '' && events !== null && events.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                events.map((value, index) =>
                                                                    <div key={index} className='float-left w-full p-2.5 hover:bg-[#f8f9fa]'>
                                                                        <div className='float-left w-full h-[230px] overflow-hidden mb-4 relative'>
                                                                            <img className='w-full h-full object-cover float-left' src={value?.image_url} alt="" />
                                                                            <span className='bg-themered text-white text-[12px] px-3 py-1 absolute left-0 top-0 z-10'>Event</span>
                                                                        </div>
                                                                        {value?.formatted_date &&
                                                                        <p className='font-WorkSans_Regular text-sm text-[#535353] pb-2'>{value?.formatted_date}</p>}
                                                                        <h3 className='font-Quicksand_Bold md:text-[20px] text-[16px] text-[#202020] pb-3 hover:text-[#0059a9]'>
                                                                            <Link to={`/event-detail/pitch/${value?.slug}`}>{value?.title}</Link>
                                                                        </h3>
                                                                        <p className='font-WorkSans_Regular text-base text-[#535353]'>{value?.short_desc}</p>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <p class="text-lg text-[#535353] font-Quicksand_Medium pb-4">Events Coming Soon</p>
                                                }
                                            </>
                                    }

                                </>
                        }
                    </div >
                    <div className="clear-both"></div>
                    <div className="flex items-center justify-center text-center pt-10">
                        {
                            last_page > 1
                            &&
                            <Pagination currentPage={current_page} totalCount={total_count} perPageRecord={length} handlePageClick={(data) => { (showing === 'blogs') ? fetchBlogs(data.selected + 1) : fetchEvents(data.selected + 1); setScroll(); }} />
                        }
                    </div>
                    <div className="clear-both"></div>
                </div >
            </div >

        </>
    )
};

export default Event;