import authContext from 'contexts/auth/authContext';
import React, { useEffect, useLayoutEffect, useState, onPageChange } from 'react';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as GeneralService from '../../../services/general.service'
import ForgotPassword from '../forms/auth/ForgotPassword';

const bag1 = `${process.env.REACT_APP_FRONT_URL}assets/images/inner_bannerbg.jpg`,
    bag2 = `${process.env.REACT_APP_FRONT_URL}assets/images/login_bg.jpg`

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


const LogIn = () => {
    const [width, height] = useWindowSize();
    const [processing, setProcessing] = useState(false);
    const [form, setForm] = useState('login');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);

    useEffect(() => {
        if (cntxAuth.isLogged) {
            navigate('/');
        }
    }, []);

    const { register, values, touched, control, handleChange, handleSubmit, formState: { errors }, clearErrors, setError, setValue, reset } = useForm();

    const saveLogin = async (data) => {
        setMessage('');
        setProcessing(true);
        const res = await GeneralService.PostForm('login', data);
        if (res.response) {
            if (res.status === false) {
                setMessage(res?.message);
                setProcessing(false);
            }
            else {
                localStorage.setItem('a4x_token', res?.data?._token);
                localStorage.setItem('a4x_user', JSON.stringify(res?.data?.user));
                localStorage.setItem('a4x_profile', JSON.stringify(res?.data?.profile));
                cntxAuth.updateLogin(true);
                cntxAuth.updateUser(res.data.user);
                setProcessing(false);
                navigate('/');
                reset();
            }
        }
        else { setProcessing(false); }
    }

    return (
        <>
            <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${bag1})` }}>
            </div>
            <div className="clear-both"></div>

            <div className='bg-white float-left w-full relative md:pb-[100px] pb-[50px]'>
                <div className='container m-auto px-4'>
                    <div className='float-left w-full -mt-[350px] shadow-lg flex md:flex-row flex-col'>
                        <div className='float-left md:w-6/12 w-full md:min-h-[690px] min-h-[350px] bg-no-repeat bg-center bg-cover flex items-center justify-center' style={{ backgroundImage: `url(${bag2})` }}>
                            <div className='font-Quicksand_Medium text-center'>
                                <p className='text-white text-[22px] pb-5'>Nice to see you again</p>
                                <h2 className='text-white lg:text-[52px] text-[40px] font-Quicksand_Bold'>Welcome Back</h2>
                            </div>
                        </div>
                        <div className='bg-white float-left md:w-6/12 w-full flex items-center justify-center relative pb-[98px]'>
                            <div className='float-left w-full md:py-0 py-6'>

                                {
                                    form === 'login'
                                        ?
                                        <>
                                            <h2 className='font-Quicksand_Bold lg:text-[30px] text-[22px] text-black pb-10 text-center'>Login Your Account</h2>
                                            {
                                                message !== ''
                                                    ?
                                                    <p className='text-themered text-center'>{message}</p>
                                                    :
                                                    null
                                            }
                                            <form onSubmit={handleSubmit(saveLogin)}>
                                                <div className='block m-auto lg:w-9/12 w-10/12 pb-3'>
                                                    <label className='float-left w-full font-WorkSans_Medium text-[#202020] text-lg leading-10 pb-2'>E-mail</label>
                                                    <input
                                                        {...register('email', {
                                                            required: {
                                                                value: true,
                                                                message: "Please enter your email!"
                                                            }
                                                        })}
                                                        type="email"
                                                        placeholder='Enter Your Email'
                                                        className='float-left w-full font-WorkSans_Regular text-black text-base leading-10 py-1.5 pb-0 px-2 border-b border-[#b5b5b5] focus:outline-none placeholder:text-[#666] focus:border-b-1 focus:border-blue focus:placeholder:text-white form-input'
                                                    />
                                                    <small className='text-red-600'>{errors?.email?.message}</small>
                                                    <div className='clear-both'></div>
                                                </div>
                                                <div className='block m-auto lg:w-9/12 w-10/12 pt-5 pb-3'>
                                                    <label className='float-left w-full font-WorkSans_Medium text-[#202020] text-lg leading-10 pb-2'>Password</label>
                                                    <input
                                                        {...register('password', {
                                                            required: {
                                                                value: true,
                                                                message: "Please enter password!"
                                                            }
                                                        })}
                                                        type="password"
                                                        placeholder='Enter Your Password'
                                                        className='float-left w-full font-WorkSans_Regular text-black text-base leading-10 py-1.5 pb-0 px-2 border-b border-[#b5b5b5] focus:outline-none placeholder:text-[#666] focus:border-b-1 focus:border-blue focus:placeholder:text-white form-input'
                                                    />
                                                    <small className='text-red-600'>{errors?.password?.message}</small>
                                                    <div className='clear-both'></div>
                                                </div>
                                                <div className='block m-auto lg:w-9/12 w-10/12 pt-5 pb-3'>
                                                    <button type="submit" className="float-left bg-themeblue font-WorkSans_Medium text-lg leading-10 py-1.5 px-8 rounded-full inline-block text-white hover:bg-themered cursor-pointer lg:w-[200px]" disabled={processing ? true : false} > {processing ? <i className='fa fa-refresh fa-spin'></i> : "Log In"}</button>
                                                    <Link onClick={() => setForm('forgot')} className="float-right font-WorkSans_Regular text-base leading-10 py-2 text-black hover:text-themeblue">Forgot Password?</Link>
                                                    <div className='clear-both'></div>
                                                </div>
                                            </form>
                                        </>
                                        :
                                        <ForgotPassword setForm={setForm} />
                                }

                            </div>
                            <div className='absolute bottom-0 left-0 right-0 bg-[#f8f9fa] p-4 text-center'>
                                <p className='font-WorkSans_Regular text-base text-black leading-8'>
                                    Not a Member? <br /><Link to={`/join-investor`} className='font-WorkSans_SemiBold text-themeblue text-[20px]'>Sign Up Now</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>
        </>
    )
};

export default LogIn;