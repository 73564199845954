import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SimpleSlider = ({ children, slidesToShow, breakpoint1, breakpoint2, breakpoint3, showarrows = false }) => {
  const sliderRef = useRef();

  var settings = {
    dots: false,
    className: "center",
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: breakpoint1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: breakpoint2
        }
      },
      {
        breakpoint: 641,
        settings: {
          slidesToShow: breakpoint3
        }
      }
    ]

    // cssEase: "linear"
  };
  return (
    <>
      {
        showarrows
          ?
          <>
            <div className="sm:flex gap-3 absolute right-0 md:-top-[60px] -top-[50px] hidden">
              <button className="bg-themeblue text-white w-[36px] leading-[36px] rounded-full hover:bg-themered transition-all" onClick={() => sliderRef?.current?.slickPrev()}><i className="fa-regular fa-chevron-left"></i></button>
              <button className="bg-themeblue text-white w-[36px] leading-[36px] rounded-full hover:bg-themered transition-all" onClick={() => sliderRef?.current?.slickNext()}><i className="fa-regular fa-chevron-right"></i></button>
            </div>
          </>
          :
          null
      }
      <Slider
        ref={sliderRef}
        {...settings}>
        {children}
      </Slider>
    </>
  );
}

export default SimpleSlider;