import React, { useEffect, useLayoutEffect, useState, onPageChange } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as GeneralService from '../../services/general.service'
import parse from 'html-react-parser';
import authContext from 'contexts/auth/authContext';
import { useContext } from 'react';

const bag1 = `${process.env.REACT_APP_FRONT_URL}assets/images/inner_bannerbg.jpg`,
    bag2 = `${process.env.REACT_APP_FRONT_URL}assets/images/investor_bg.jpg`

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const EventDetail = () => {
    const cntxAuth = useContext(authContext);
    const [width, height] = useWindowSize();
    const [blog, setBlog] = useState('');
    const { type, url } = useParams();
    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        let call_url = `get-blog/${url}`;
        if (type === 'pitch') {
            call_url = `pitch-calls/${url}`;
        }
        const res = await GeneralService.GetContentAPI(call_url);
        if (res.response) {
            if (type === 'pitch') {
                setBlog(res?.data);
            } else {
                setBlog(res?.data?.blogs);
            }
        }
        else { }
    }

    return (
        <>
            <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${bag1})` }}>

            </div>
            <div className="clear-both"></div>

            <div className='bg-white float-left w-full relative md:pb-[100px] pb-[50px] bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${bag2})` }}>
                <div className='container m-auto px-4'>
                    <div className='float-left w-full -mt-[350px] shadow-lg bg-white px-6 py-5'>
                        <div className="float-left w-full pb-10">
                            <h2 className="font-Quicksand_Bold text-black md:text-[26px] text-[20px] pb-3">{blog?.title}</h2>
                            <span className="w-[60px] h-[2px] bg-themered block"></span>
                        </div>
                        <div className="float-left w-full h-[600px] overflow-hidden mb-8">
                            <img className='float-left w-full h-full object-cover' src={blog?.image_url} alt={blog?.title} />
                        </div>
                        <div className="float-left w-full blog_detail">
                            {
                                type === 'blog'
                                    ?
                                    <>
                                        {parse(blog?.long_description ? blog?.long_description : '')}
                                    </>
                                    :
                                    <>
                                        {parse(blog?.description ? blog?.description : '')}
                                        <div className='m-auto px-4 py-4 text-center'>
                                            {
                                                cntxAuth.isLogged
                                                    ?
                                                    <></>
                                                    :
                                                    <Link to={'/join-investor'} className="clear-both bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 px-8 rounded-full inline-block text-white hover:bg-themered">Register</Link>
                                            }
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

        </>
    )
};

export default EventDetail;