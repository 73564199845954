import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { Button, Modal, Radio, Checkbox } from "flowbite-react";
import * as GeneralService from '../../../../services/general.service'
import fns from "helpers/functions";

const JoinCommunity = () => {
    const [identification_list, setIdentificationList] = useState('');
    const [sectors_list, setSectorList] = useState('');
    const [proccessing, setProccessing] = useState(false);
    const [show_popup, setShowPopup] = useState(false);
    const [popup_message, setPopupMessage] = useState('');
    const [selected_file, setSelectedFile] = useState('');
    const [new_file, setNewFile] = useState('');

    useEffect(() => {
        fetchFields();
    }, []);

    const setScroll = () => {
        let scroll_box = document.getElementById('scroll_box');
        scroll_box.scrollIntoView({ behavior: 'smooth' });
    }

    const fetchFields = async () => {
        const res = await GeneralService.GetContentAPI('get-dropdown-list?type=all');
        const sector_list = await GeneralService.GetContentAPI('get-dropdown-list?type=sector_list');

        if (res.response) {
            setIdentificationList(res?.data?.community_identification_types);
        }
        if (sector_list.response) {
            setSectorList(sector_list?.data)
        }
        else { }
    }

    const imageChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file.name);
        setNewFile(file);
        clearErrors('file');
    }

    const removeImage = () => {
        setSelectedFile('');
        setValue('file', '');
    }

    const { register, values, touched, control, handleChange, handleSubmit, formState: { errors }, clearErrors, setError, setValue, reset } = useForm();

    const saveData = async (data) => {
        if (new_file === '') {
            setError('file', { type: "focus", message: 'Please Upload a Picture.' }, { shouldFocus: true });
            return false;
        }

        if (data.identification === 'Other') {
            data.identification = data.identification_other !== '' ? data.identification_other : 'Other';
        }
        
        setProccessing(true);
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('residing_city', data.residing_city);
        formData.append('phone', data.phone);
        formData.append('email', data.email);
        formData.append('organization_and_designation', data.organization_and_designation);
        formData.append('linkedin', data.linkedin);
        formData.append('identification', data.identification);
        formData.append('about_yourself', data.about_yourself);
        formData.append('file', new_file);
        formData.append('sector', data?.sector)

        if (data.sector_other_value && data?.sector_other_value !== '') {
            formData.append('sector_other_value', data?.sector_other_value)
        }

        const res = await GeneralService.PostForm('community-members/create', formData);
        if (res?.response) {
            if (res.status === false) {
                fns.TOAST_ERR_ONE(res.data);
            }
            else {
                setShowPopup(true);
                setPopupMessage(res?.message);
                setScroll();
                reset();
                setSelectedFile('');
            }
            setProccessing(false);
        }
        else { setProccessing(false); }
        setScroll();
    }

    return (
        <>
            <div className='float-left w-full' id="scroll_box">
                <div className='float-left w-full pb-5'>
                    <h4 className='font-Quicksand_Bold text-black md:text-[22px] text-[18px] pb-3'>Generic Information</h4>
                    <span className='w-[60px] h-[2px] bg-themered block'></span>
                </div>
                <form onSubmit={handleSubmit(saveData)} encType="multipart/form-data">
                    <div className='float-left w-full pb-10'>
                        <div className="md:grid md:grid-cols-2 md:gap-10 flex flex-col md:flex-row gap-10">
                            <div className='float-left w-full pb-3'>
                                <label className='float-left w-full font-WorkSans_Medium text-[#202020] text-base leading-10'>Full Name of Applicant <span className='text-themered'>*</span></label>
                                <input
                                    {...register('name', {
                                        required: {
                                            value: true,
                                            message: "Please enter your name!"
                                        }
                                    })}
                                    type="text"
                                    placeholder='Your Answer'
                                    className='float-left w-full font-WorkSans_Regular text-black text-base leading-10 py-1.5 pb-0 px-2 border-b border-[#b5b5b5] focus:outline-none placeholder:text-[#666] focus:border-b-1 focus:border-blue focus:placeholder:text-white form-input'
                                />
                                <small className='text-red-600'>{errors?.name?.message}</small>                            <div className='clear-both'></div>
                            </div>
                            <div className='float-left w-full pb-3'>
                                <label className='float-left w-full font-WorkSans_Medium text-[#202020] text-base leading-10'>Residing City <span className='text-themered'>*</span></label>
                                <input
                                    {...register('residing_city', {
                                        required: {
                                            value: true,
                                            message: "Please enter city!"
                                        }
                                    })}
                                    type="text"
                                    placeholder='Your Answer'
                                    className='float-left w-full font-WorkSans_Regular text-black text-base leading-10 py-1.5 pb-0 px-2 border-b border-[#b5b5b5] focus:outline-none placeholder:text-[#666] focus:border-b-1 focus:border-blue focus:placeholder:text-white form-input'
                                />
                                <small className='text-red-600'>{errors?.residing_city?.message}</small>
                                <div className='clear-both'></div>
                            </div>
                            <div className='float-left w-full pb-3'>
                                <label className='float-left w-full font-WorkSans_Medium text-[#202020] text-base leading-10'>Mobile Number <span className='text-themered'>*</span></label>
                                <input
                                    {...register('phone', {
                                        required: {
                                            value: true,
                                            message: "Please enter contact number!"
                                        },
                                        pattern: {
                                            value: /^(\+\d{1,3}[- ]?)?\d{8,16}$/,
                                            message: "Please enter minimum 8 and maximum 16 digit"
                                        },
                                    })}
                                    type="text"
                                    placeholder='Your Answer'
                                    className='float-left w-full font-WorkSans_Regular text-black text-base leading-10 py-1.5 pb-0 px-2 border-b border-[#b5b5b5] focus:outline-none placeholder:text-[#666] focus:border-b-1 focus:border-blue focus:placeholder:text-white form-input'
                                />
                                <small className='text-red-600'>{errors?.phone?.message}</small>                            <div className='clear-both'></div>
                            </div>
                            <div className='float-left w-full pb-3'>
                                <label className='float-left w-full font-WorkSans_Medium text-[#202020] text-base leading-10'>Email ID <span className='text-themered'>*</span></label>
                                <input
                                    {...register('email', {
                                        required: {
                                            value: true,
                                            message: "Please enter email!"
                                        }
                                    })}
                                    type="email"
                                    placeholder='Your Answer'
                                    className='float-left w-full font-WorkSans_Regular text-black text-base leading-10 py-1.5 pb-0 px-2 border-b border-[#b5b5b5] focus:outline-none placeholder:text-[#666] focus:border-b-1 focus:border-blue focus:placeholder:text-white form-input'
                                />
                                <small className='text-red-600'>{errors?.email?.message}</small>                            <div className='clear-both'></div>
                            </div>
                            <div className='float-left w-full pb-3'>
                                <label className='float-left w-full font-WorkSans_Medium text-[#202020] text-base leading-10'>Organization & Designation</label>
                                <input
                                    {...register('organization_and_designation')}
                                    type="text"
                                    placeholder='Your Answer'
                                    className='float-left w-full font-WorkSans_Regular text-black text-base leading-10 py-1.5 pb-0 px-2 border-b border-[#b5b5b5] focus:outline-none placeholder:text-[#666] focus:border-b-1 focus:border-blue focus:placeholder:text-white form-input'
                                />
                                <div className='clear-both'></div>
                            </div>
                            <div className='float-left w-full pb-3'>
                                <label className='float-left w-full font-WorkSans_Medium text-[#202020] text-base leading-10'>LinkedIn <span className='text-themered'>*</span></label>
                                <input
                                    {...register('linkedin', {
                                        required: {
                                            value: true,
                                            message: "Please enter linkedin url!"
                                        },
                                        pattern: {
                                            value: /^((http|https?|ftp|smtp):\/\/)|(www.)|[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
                                            message: "Please enter valid URL.(Ex: www.example.com)"
                                        },
                                    })}
                                    type="text"
                                    placeholder='Your Answer'
                                    className='float-left w-full font-WorkSans_Regular text-black text-base leading-10 py-1.5 pb-0 px-2 border-b border-[#b5b5b5] focus:outline-none placeholder:text-[#666] focus:border-b-1 focus:border-blue focus:placeholder:text-white form-input'
                                />
                                <small className='text-red-600'>{errors?.linkedin?.message}</small>
                                <div className='clear-both'></div>
                            </div>
                        </div>
                        <div className='float-left w-full pb-3 my-10'>
                            <label className='float-left w-full font-WorkSans_Medium text-[#202020] text-base leading-10'>How Do You Identify Yourself <span className='text-themered'>*</span></label>
                            <div className="float-left w-full flex flex-col gap-3">
                                {
                                    identification_list !== '' && typeof identification_list !== 'undefined'
                                    &&
                                    Object.values(identification_list).map((field, index) =>
                                        <div key={index}>
                                            <div className="flex items-center">
                                                <Radio
                                                    {...register(`identification`, {
                                                        required: {
                                                            value: true,
                                                            message: "Please choose your identification type!"
                                                        }
                                                    })}
                                                    id={`identify${index}`}
                                                    onChange={(e) => { clearErrors(`identification`); }}
                                                    value={field}
                                                    className="h-4 w-4"
                                                />
                                                <label htmlFor={`identify${index}`} className="pl-3 text-base font-WorkSans_Regular text-[#535353]">{field}</label>
                                            </div>
                                            {
                                                (field === 'Other')
                                                &&
                                                <input
                                                    onChange={(e) => { setValue('identification_other', e.target.value); }}
                                                    className='float-left w-full font-WorkSans_Regular text-black text-base px-2 border-b border-[#b5b5b5] focus:outline-none placeholder:text-[#ccc] focus:border-b-1 focus:border-blue focus:placeholder:text-white form-input'
                                                    type="text"
                                                    placeholder='Your Answer'
                                                />
                                            }
                                        </div>
                                    )
                                }
                                <small className='text-red-600'>{errors?.identification?.message}</small>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                        <div className='clear-both'></div>
                        <div className="md:grid md:grid-cols-2 md:gap-10 flex flex-col md:flex-row gap-10">
                            <div className='float-left w-full pb-3'>
                                <label className='float-left w-full font-WorkSans_Medium text-[#202020] text-base leading-7 pb-3'>Please share a short brief about yourself and how do you plan to engage with startup through A4X?</label>
                                <textarea
                                    {...register(`about_yourself`)}
                                    name="about_yourself"
                                    id="about_yourself"
                                    cols="30"
                                    rows="10"
                                    className='float-left w-full font-WorkSans_Regular text-black text-base py-1.5 pb-0 px-2 border-0 bg-[#f8f9fa] focus:outline-none placeholder:text-[#666] focus:border-b-1 h-28 resize-none rounded-xl focus:border-blue focus:placeholder:text-white form-input'
                                    placeholder='Your Answer'
                                ></textarea>
                                <div className='clear-both'></div>
                            </div>
                            <div className='float-left w-full pb-3'>
                                <label className='float-left w-full font-WorkSans_Medium text-[#202020] text-base leading-7 pb-3'>Please Upload a Picture of You<br />Logo of The Firm You Represent <span className='text-themered'>*</span></label>
                                <div className="float-left w-full">
                                    <input
                                        {...register(`file`, {
                                            required: {
                                                value: true,
                                                message: "Please Upload a Picture!"
                                            }
                                        })}
                                        onChange={imageChange}
                                        type="file"
                                        name="file"
                                        id="file"
                                        className="sr-only" />

                                    {
                                        selected_file !== ''
                                            ?
                                            <label htmlFor="" className="relative flex gap-14 min-h-[100px] items-center justify-center rounded-xl border border-dashed border-[#666] font-WorkSans_Regular text-base p-3 cursor-pointer">
                                                {selected_file}
                                                <button onClick={removeImage} className="duration-200 ease-in-out transition bg-themered font-WorkSans_Medium text-sm leading-[36px] rounded-full inline-block text-white hover:bg-themeblue w-[36px]">
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </label>
                                            :
                                            <>
                                                <label htmlFor="file" className="relative flex gap-14 min-h-[100px] items-center justify-center rounded-xl border border-dashed border-[#666] font-WorkSans_Regular text-base p-3 cursor-pointer">
                                                    <img className='w-[55px] float-left' src={`${process.env.REACT_APP_FRONT_URL}assets/images/upload.svg`} alt="" />
                                                    <div>
                                                        <span className="block ">Drag & Drop Your File Here</span>
                                                        <span className="inline-block">Or </span>
                                                        <span className="ml-2 inline-block text-themeblue">Select File</span>
                                                        <span className="block text-[12px]">(JPG, JPEG, BMP, PNG)</span>
                                                    </div>
                                                </label>
                                            </>
                                    }
                                    <small className='text-red-600'>{errors?.file?.message}</small>
                                </div>
                                <div className='clear-both'></div>
                            </div>
                        </div>
                        <div className='float-left w-full pb-3 my-10'>
                            <label className='float-left w-full font-WorkSans_Medium text-[#202020] text-base leading-10'>Sector you are into <span className='text-themered'>*</span></label>
                            <div className="float-left w-full flex flex-col gap-3">
                                {
                                    sectors_list !== '' && typeof sectors_list !== 'undefined'
                                    &&
                                    Object?.values(sectors_list)?.map((field, index) =>
                                        <div key={index} >
                                            <div className="flex items-center">
                                                <Checkbox
                                                    {...register(`sector`, {
                                                        required: {
                                                            value: true,
                                                            message: "Please choose your sector type!"
                                                        }
                                                    })}
                                                    id={`sector${index}`}
                                                    onChange={(e) => { clearErrors(`sector`); }}
                                                    value={Object.keys(sectors_list).find(
                                                        (key) => sectors_list[key] === field)}
                                                    className="h-4 w-4"
                                                    group={"ok"}
                                                />
                                                <label htmlFor={`sector${index}`} className="pl-3 text-base font-WorkSans_Regular text-[#535353]">{field}</label>
                                            </div>
                                            {
                                                (field === 'Others')
                                                &&
                                                <input
                                                    onChange={(e) => { setValue('sector_other_value', e.target.value); }}
                                                    className='float-left w-full font-WorkSans_Regular text-black text-base px-2 border-b border-[#b5b5b5] focus:outline-none placeholder:text-[#ccc] focus:border-b-1 focus:border-blue focus:placeholder:text-white form-input'
                                                    type="text"
                                                    placeholder='Your Answer'
                                                />
                                            }
                                        </div>
                                    )
                                }
                                <small className='text-red-600'>{errors?.sector?.message}</small>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                        <div className='clear-both'></div>
                        <div className="float-left w-full pb-3 ">
                            <div className="flex items-center">
                                <Checkbox
                                    {...register(`terms_condition`, {
                                        required: {
                                            value: true,
                                            message: "Please accept terms & condition!"
                                        }
                                    })}

                                />
                                <label className="pl-3  text-base font-WorkSans_Regular text-[#535353]">
                                I confirm that I have read and agree to the&nbsp;
                                    <Link  target="_blank" className='text-themeblue' to={`/page/terms-conditions`}>Terms & Conditions</Link> </label>
                            </div>
                            <small className='text-red-600'>{errors?.terms_condition?.message}</small>
                            <div className='clear-both'></div>
                        </div>
                    </div>
                    <div className='float-left w-full flex-auto flex flex-row-reverse items-center justify-center gap-5'>
                        <button type="submit" className="duration-200 ease-in-out transition bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 rounded-full inline-block text-white hover:bg-themered md:w-[160px] w-[120px]" disabled={proccessing ? true : false}>{proccessing ? <i className="fa fa-refresh fa spin"></i> : "Submit"}</button>
                    </div>
                </form>
            </div>
            <Modal
                show={show_popup}
                size="md"
                popup={true}
                onClick={() => setShowPopup(false)}
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {popup_message}
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={() => setShowPopup(false)} >
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default JoinCommunity;