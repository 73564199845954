import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as GeneralService from '../../services/general.service'
import parse from 'html-react-parser';
import { useState } from 'react';
import ParticleSection from 'components/plugins/ParticleSection';

const bag1 = `${process.env.REACT_APP_FRONT_URL}assets/images/inner_bannerbg.jpg`,
    bag2 = `${process.env.REACT_APP_FRONT_URL}assets/images/investor_bg.jpg`


const InvestorForm = () => {
    const { id } = useParams();
    const [deal, setDeal] = useState();
    const [message, setMessage] = useState('');
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        fetchContent();
    }, []);

    const fetchContent = async () => {
        const res = await GeneralService.GetTokenContentAPI(`deals/${id}/detail`);
        if (res.response) {
            setDeal(res?.data);
        }
        else { }
    }

    const saveInterest = async () => {
        setMessage('');
        setProcessing(true);
        const form = new FormData();
        form.append('is_interested', 1);
        const res = await GeneralService.PostTokenForm(`deals/${id}/update_user_deal_interest`, form);
        if (res.response) {
            if (res.status === false) {
                setMessage(res?.message);
                setProcessing(false);
            }
            else {
                setMessage(res?.message);
                fetchContent();
                setProcessing(false);
            }
        }
        else { setProcessing(false); }
    }

    return (
        <>
            <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${bag1})` }}>

            </div>
            <div className="clear-both"></div>

            <div className='bg-white float-left w-full relative md:pb-[100px] pb-[50px] bg-gradient-to-l from-[#cfe3f4] to-[#f8f9fa]'>
                <div className="absolute right-0 top-0 md:w-6/12 w-full bottom-0 right_shap">
                    <ParticleSection></ParticleSection>
                </div>
                <div className='container m-auto px-4 relative z-[1]'>
                    <div className="float-left w-full -mt-[350px] shadow-lg px-5 py-5 sm:py-10 bg-white">
                        <div className='w-full float-left flex lg:flex-row flex-col gap-10 mb-5'>
                            <div className='lg:w-6/12 w-full float-left'>
                                <div className='flex flex-row gap-7 items-center border-b border-[#ebebeb] pb-5 mb-2'>
                                    <div className='w-[110px] h-[110px] border border-[#d6d6d6] rounded-xl overflow-hidden'>
                                        <img src={deal?.image_url} alt="" />
                                    </div>
                                    <div className='w-9/12 float-left'>
                                        <h3 className='font-Quicksand_Bold md:text-[36px] text-[20px] text-[#202020] pb-1'>{deal?.title}</h3>
                                        <p className='font-WorkSans_Regular text-base text-[#535353]'>{deal?.tagline}</p>
                                    </div>
                                </div>
                                <div className='w-full float-left font-WorkSans_Regular text-base'>
                                    <p className='float-left w-full font-WorkSans_Medium text-[#28b82d] text-xl leading-10 pb-2.5'>Raised {deal?.raised_percentage}</p>
                                    <div className='w-full float-left relative pb-2'>
                                        <div className='w-full h-2 bg-[#f8f9fa] overflow-hidden rounded-full'>
                                            <div className={`bg-[#28b82d] h-2`} style={{ width: `${parseInt(deal?.raised).toString()}%` }}></div>
                                        </div>
                                        <span className={`bg-[#28b82d] w-[14px] h-[14px] rounded-full float-left absolute -top-[3px]`} style={{ left: `${(parseInt(deal?.raised) - 1).toString()}%` }}></span>
                                    </div>
                                    <p className='float-left font-WorkSans_Medium text-[#535353] text-base leading-8'>{deal?.formatted_achieved} From {deal?.achieved_from} Investors</p>
                                    <p className='float-right font-WorkSans_Medium text-[#535353] text-base leading-8'>{deal?.formatted_target} Target</p>
                                </div>
                            </div>
                            <div className='lg:w-6/12 w-full float-left'>
                                <ul className='w-full float-left m-0 p-0'>
                                    <li className='w-full float-left py-2 border-b font-WorkSans_Regular text-[#202020] text-base'>
                                        <strong className='font-WorkSans_SemiBold sm:max-w-[240px] max-w-[200px] w-full float-left'>Location</strong>{deal?.location}
                                    </li>
                                    <li className='w-full float-left py-2 border-b font-WorkSans_Regular text-[#202020] text-base'>
                                        <strong className='font-WorkSans_SemiBold sm:max-w-[240px] max-w-[200px] w-full float-left'>Startup Sector</strong>{deal?.survey_name}
                                    </li>
                                    <li className='w-full float-left py-2 border-b font-WorkSans_Regular text-[#202020] text-base'>
                                        <strong className='font-WorkSans_SemiBold sm:max-w-[240px] max-w-[200px] w-full float-left'>Company Number</strong>{deal?.company_number}
                                    </li>
                                    <li className='w-full float-left py-2 border-b font-WorkSans_Regular text-[#202020] text-base'>
                                        <strong className='font-WorkSans_SemiBold sm:max-w-[240px] max-w-[200px] w-full float-left'>Incorporation Date</strong>{deal?.formatted_incorporation_date}
                                    </li>
                                    <li className='w-full float-left py-2 border-b font-WorkSans_Regular text-[#202020] text-base'>
                                        <strong className='font-WorkSans_SemiBold sm:max-w-[240px] max-w-[200px] w-full float-left'>Website</strong>{deal?.website}
                                    </li>
                                    <li className='w-full float-left py-2 border-b font-WorkSans_Regular text-[#202020] text-base'>
                                        <strong className='font-WorkSans_SemiBold sm:max-w-[240px] max-w-[200px] w-full float-left'>Social Media</strong>
                                        {
                                            deal?.instagram !== '' && deal?.instagram !== null
                                                ?
                                                <a href={deal?.instagram} target="_blank" className='inline-block mr-4 text-xl text-[#202020]'><i className="fa-brands fa-instagram"></i></a>
                                                :
                                                null
                                        }
                                        {
                                            deal?.twitter !== '' && deal?.twitter !== null
                                                ?
                                                <a href={deal?.twitter} target="_blank" className='inline-block mr-4 text-xl text-[#202020]'><i className="fa-brands fa-twitter"></i></a>
                                                :
                                                null
                                        }
                                        {
                                            deal?.linkedin !== '' && deal?.linkedin !== null
                                                ?
                                                <a href={deal?.linkedin} target="_blank" className='inline-block mr-4 text-xl text-[#202020]'><i className="fa-brands fa-linkedin"></i></a>
                                                :
                                                null
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='w-full float-left pt-10'>
                            <div className='float-left w-full pb-5'>
                                <h4 className='font-Quicksand_Bold text-black md:text-[22px] text-[18px] pb-3'>Startup Overview</h4>
                                <span className='w-[60px] h-[2px] bg-themered block'></span>
                            </div>
                            {parse(deal?.description ? deal?.description : '')}
                        </div>
                        <div className='w-full float-left pt-8'>
                            <div className='float-left w-full pb-5'>
                                <h4 className='font-Quicksand_Bold text-black md:text-[22px] text-[18px] pb-3'>For More Details to Download The Documents</h4>
                                <span className='w-[60px] h-[2px] bg-themered block'></span>
                            </div>
                            <ul className='float-left w-full p-0 m-0'>
                                {
                                    deal?.pitch_deck_document !== '' && deal?.pitch_deck_document !== null
                                        ?
                                        <li className='float-left w-full py-2 relative pl-6'>
                                            <a href={deal?.pitch_deck_document?.document_url} target="_blank" className='font-WorkSans_Regular text-[#535353] text-base hover:text-themered' download>
                                                <i className="fa-light fa-file-pdf absolute left-0 top-1.4 text-xl"></i> {deal?.pitch_deck_document?.document_name}
                                            </a>
                                        </li>
                                        :
                                        null
                                }

                                {
                                    deal?.other_documents !== null && deal?.other_documents.length > 0
                                        ?
                                        <>
                                            {
                                                deal?.other_documents.map((value, index) =>
                                                    <li key={index} className='float-left w-full py-2 relative pl-6'>
                                                        <a href={value?.document_url} target="_blank" className='font-WorkSans_Regular text-[#535353] text-base hover:text-themered' download>
                                                            <i className="fa-light fa-file-pdf absolute left-0 top-1.4 text-xl"></i> {value?.document_name}
                                                        </a>
                                                    </li>
                                                )
                                            }
                                        </>
                                        :
                                        null
                                }

                            </ul>
                        </div>
                        {
                            message !== ''
                                ?
                                <p className='text-themered text-center'>{message}</p>
                                :
                                null
                        }
                        {
                            deal?.is_interested === 1
                                ?
                                <p className='text-themeblue text-center'>We have recorded your interest in this deal!</p>
                                :
                                <div className='w-full float-left pt-8 text-center'>
                                    <Link className="clear-both bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 px-8 rounded-full m-auto inline-block text-white hover:bg-themered" onClick={saveInterest} disabled={processing ? true : false} > {processing ? <i className='fa fa-refresh fa-spin'></i> : "Interested"}</Link>
                                </div>
                        }
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>

        </>
    )
};

export default InvestorForm;