import toast from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
// eslint-disable-next-line

const fns = {
    STR_REPLACE: (og_str, search, replace) => {
        return typeof og_str === 'string' ? og_str.replace(search, replace) : '';
    },
    TOGGLE_ARR_ELE: (collection, item) => {
        var newcollection = collection;
        var idx = newcollection.indexOf(item);
        if (idx !== -1) {
            newcollection.splice(idx, 1);
        } else {
            newcollection.push(item);
        }

        return newcollection;
    },
    IS_UNDEFINED: (val) => {
        return typeof val === 'undefined';
    },
    TOAST_BOX: (color, type, message) => {
        setTimeout(() => {
            toast.custom((t) => (
                <div
                    className={`animate-enter animate-leave max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                    <div className={`flex-1 w-0 p-4`}>
                        <div className={`flex items-start`}>
                            <div className={`ml-3 flex-1`}>
                                <p className={`text-sm font-medium ${color}`}>{type}</p>
                                <p className={`mt-1 text-sm text-gray-500`}>{message}</p>
                            </div>
                        </div>
                    </div>
                    <div className={`flex border-l border-gray-200`}>
                        <button
                            onClick={() => toast.dismiss(t.id)}
                            className={`w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500`}
                        >
                            Close
                        </button>
                    </div>
                </div>
            ));
        }, 200);
    },
    CONFIRM_BOX: (title, message, yesLabel, noLabel, yesFn, noFn = null) => {
        confirmAlert({
            title: title,
            message: message,
            buttons: [{
                label: yesLabel,
                className: '!bg-burgandy !p-3 !px-6 !text-white !font-Averta_Semibold !text-sm',
                onClick: () => yesFn()
            }, {
                label: noLabel,
                className: '!bg-blue !p-3 !px-6 !text-white !font-Averta_Semibold !text-sm',
                onClick: () => noFn
            }],
            closeOnClickOutside: true,
        });
    },
    COMMING_SOON_BOX: () => {
        confirmAlert({
            title: "Coming soon",
            message: "This feature isn't available right now.",
            buttons: [{
                label: "OK",
                className: '!bg-blue !p-3 !px-6 !text-white !font-Averta_Semibold !text-sm',
                onClick: () => { }
            }],
            closeOnClickOutside: true,
        });
    },
    INFO_BOX: (title, message) => {
        setTimeout(() => {
            toast.custom((t) => (
                <div
                    className={`animate-enter animate-leave max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                    <div className={`flex-1 w-0 p-4`}>
                        <div className={`flex items-start`}>
                            <div className={`ml-3 flex-1`}>
                                <p className={`text-sm font-medium text-green-500`}>{title}</p>
                                <p className={`mt-1 text-sm text-gray-500`}>{message}</p>
                            </div>
                        </div>
                    </div>
                    <div className={`flex border-l border-gray-200`}>
                        <button
                            onClick={() => toast.dismiss(t.id)}
                            className={`w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500`}
                        >
                            Close
                        </button>
                    </div>
                </div>
            ));
        }, 200);
    },
    TOAST_SUC: (msg) => {
        fns.TOAST_BOX(`text-green-500`, 'Success!', msg);
    },
    TOAST_WAR: (msg) => {
        fns.TOAST_BOX(`text-orange-500`, 'Warning!', msg);
    },
    TOAST_INFO: (msg) => {
        fns.TOAST_BOX(`text-sky-500`, 'Info!', msg);
    },
    TOAST_ERR_ONE: (msg) => {
        fns.TOAST_BOX(`text-red-500`, 'Failed!', msg);
    },
    TOAST_ERR: (errors) => {
        Object.keys(errors).forEach((key, index) => {
            fns.TOAST_BOX(`text-red-500`, 'Failed!', errors[key][0]);
        });
    }
}

export default fns;