import React, { useCallback } from 'react';
import Particles from "react-tsparticles";
import { Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";

const ParticleSection = ({ id, particleOptions, className }) => {

    const customInit = useCallback(async (engine: Engine, options: any) => {
        // this adds the bundle to tsParticles
        await loadFull(engine);
    });

    const options = {
        fullScreen: {
            enable: false,
            zIndex: 0
        },
        particles: {
            number: {
                value: 120,
                density: {
                    enable: true,
                    value_area: 800
                }
            },
            color: {
                value: "#94c8f2"
            },
            shape: {
                type: "circle",
                stroke: {
                    width: 0,
                    color: "#94c8f2"
                },
                polygon: {
                    nb_sides: 5
                },
                image: {
                    src: "images/github.svg",
                    width: 100,
                    height: 100
                }
            },
            opacity: {
                value: 0.5,
                random: true,
                anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0.5,
                    sync: false
                }
            },
            size: {
                value: 3,
                random: true,
                anim: {
                    enable: true,
                    speed: 7,
                    size_min: 7,
                    sync: false
                }
            },
            line_linked: {
                enable: true,
                distance: 120,
                color: "#94c8f2",
                opacity: 1,
                width: 1
            },
            move: {
                enable: true,
                speed: 3,
                direction: "none",
                random: false,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200
                }
            }
        },
        interactivity: {
            detect_on: "canvas",
            events: {
                onHover: {
                    enable: true,
                    mode: "grab",
                    parallax: {
                        enable: false,
                        force: 5,
                        smooth: 2
                    }
                },
                onClick: {
                    enable: true,
                    mode: "push"
                },
                resize: true
            },
            modes: {
                grab: {
                    distance: 200,
                    lineLinked: {
                        opacity: 1
                    }
                },
                bubble: {
                    distance: 400,
                    size: 100,
                    duration: 2,
                    opacity: 1,
                    speed: 2
                },
                repulse: {
                    distance: 200
                },
                push: {
                    particles_nb: 4
                },
                remove: {
                    particles_nb: 2
                }
            }
        },
        backgroundMask: {
            enable: false,
            cover: {
                color: {
                    value: {
                        r: 0,
                        g: 0,
                        b: 0
                    }
                }
            }
        },
        retina_detect: true,
        fps_limit: 60,
    };

    return (
        <>
            {
                typeof particleOptions !== 'undefined'
                    ?
                    <Particles id={id} className={className} options={particleOptions} init={customInit} />
                    :
                    <Particles className='w-full h-full' options={options} init={customInit} />
            }
        </>
    );
}

export default ParticleSection;
