import ReactPaginate from 'react-paginate';

const Pagination = (props) => {
    let pageCount = Math.ceil(props.totalCount / props.perPageRecord);

    return (
        <>
            <ReactPaginate
                forcePage={0}
                previousLabel={<i className="fa-regular fa-arrow-left-from-line"></i>}
                nextLabel={<i className="fa-regular fa-arrow-right-from-line"></i>}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={1}
                onPageChange={props.handlePageClick}
                containerClassName={'flex items-center justify-center text-center gap-3'}
                pageClassName={''}
                pageLinkClassName={'bg-slate-50 w-[36px] h-[36px] text-center leading-[36px] cursor-pointer rounded-md hover:bg-themeblue hover:text-white block'}
                previousClassName={''}
                previousLinkClassName={'bg-slate-50 w-[36px] h-[36px] text-center leading-[36px] cursor-pointer rounded-md hover:bg-themeblue hover:text-white block'}
                nextClassName={''}
                nextLinkClassName={'bg-slate-50 w-[36px] h-[36px] text-center leading-[36px] cursor-pointer rounded-md hover:bg-themeblue hover:text-white block'}
                activeClassName={' selected bg-themeblue'}
            />
        </>
    );
}

export default Pagination