import ParticleSection from "components/plugins/ParticleSection";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as GeneralService from "../../services/general.service";
import parse from "html-react-parser";
import authContext from "contexts/auth/authContext";
import { useContext } from "react";
import { CmsBannerLoader } from "components/interfaces/SkeletonLoader";
import { Modal } from "flowbite-react";

const bag1 = `${process.env.REACT_APP_FRONT_URL}assets/images/inner_bannerbg.jpg`,
  bag2 = `${process.env.REACT_APP_FRONT_URL}assets/images/about_gvfl_bg.jpg`;

const AboutUs = () => {
  const [banner, setBanner] = useState("");
  const [a4x, setA4x] = useState("");
  const [gvfl, setGvfl] = useState("");
  const [members, setMembers] = useState("");
  const cntxAuth = useContext(authContext);
  const [processing, setProcessing] = useState(false);
  const [show_popup, setShowPopup] = useState(false);
  const [memberOnModal, setMemberOnModal] = useState("");

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    setProcessing(true);
    const res = await GeneralService.GetContentAPI("get-about-us");
    if (res.response) {
      const sortByOrderTeamMember = res?.data?.team_members?.sort(
        (a, b) => a?.display_order - b.display_order
      );
      setBanner(res?.data?.banner_data);
      setA4x(res?.data?.aboutUsA4X?.cms);
      setGvfl(res?.data?.aboutUsGVFL?.cms);
      setMembers(sortByOrderTeamMember);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };

  const fetchMemberDetails = (member) => {
    setMemberOnModal(member);
    setShowPopup(true);
  };

  return (
    <>
      {!processing && banner !== "" && banner !== null ? (
        <div
          className="bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover"
          style={{ backgroundImage: `url(${bag1})` }}
        >
          <div className="absolute z-[1] m-auto inset-y-2/4 w-full flex items-center h-fit md:pt-[91px]">
            <div className="container m-auto px-4">
              <div className="flex justify-between items-center">
                <div className="md:w-6/12 w-full">
                  {parse(
                    banner?.short_description ? banner?.short_description : ""
                  )}
                  <div className="clear-both"></div>
                  {/* {
                                            cntxAuth.isLogged
                                                ?
                                                <></>
                                                :
                                                <Link to={`/join-investor`} className='clear-both bg-themeblue font-WorkSans_Medium text-base leading-10 py-1.5 px-8 rounded-full inline-block text-white hover:bg-themered'>Join Now</Link>
                                        } */}
                </div>
                <div className="md:w-6/12 w-full md:block hidden">
                  <img
                    className="w-[510px] float-right"
                    src={banner?.image_url}
                    alt={banner?.title}
                  />
                </div>
              </div>
              <div className="clear-both"></div>
            </div>
          </div>
        </div>
      ) : (
        <CmsBannerLoader count={1} image={bag1} />
      )}

      <div className="clear-both"></div>

      {a4x !== "" && a4x !== null && (
        <div className="bg-white float-left w-full overflow-hidden relative md:py-[100px] py-[50px]">
          <div className="container m-auto px-4">
            <div className="flex justify-between items-center md:flex-row flex-col gap-10">
              <div className="lg:w-4/12 md:w-6/12 w-full md:order-1">
                <img
                  className="max-w-[340px] w-full block m-auto"
                  src={a4x?.image_url}
                  alt={a4x?.title}
                />
              </div>
              <div className="lg:w-8/12 md:w-6/12 w-full md:order-2">
                <div className="float-left w-full pb-10">
                  <h2 className="font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3">
                    {a4x?.title}
                  </h2>
                  <span className="w-[60px] h-[2px] bg-themered block"></span>
                </div>
                {parse(a4x?.long_description ? a4x?.long_description : "")}
              </div>
            </div>
            <div className="clear-both"></div>
          </div>
        </div>
      )}

      <div className="clear-both"></div>

      {gvfl !== "" && gvfl !== null && (
        <div className="bg-[#f8f9fa] float-left w-full overflow-hidden md:py-[100px] py-[50px] relative bg-gradient-to-l from-[#cfe3f4] to-[#f8f9fa]">
          <div className="absolute right-0 top-0 md:w-6/12 w-full bottom-0 right_shap">
            <ParticleSection></ParticleSection>
          </div>
          <div className="container m-auto px-4 relative z-[2]">
            <div className="flex justify-between items-center md:flex-row flex-col gap-10">
              <div className="lg:w-4/12 md:w-6/12 w-full md:order-2">
                <img
                  className="max-w-[410px] w-full block m-auto"
                  src={gvfl?.image_url}
                  alt=""
                />
              </div>
              <div className="lg:w-8/12 md:w-6/12 w-full md:order-1">
                <div className="float-left w-full pb-10">
                  <h2 className="font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3">
                    {gvfl?.title}
                  </h2>
                  <span className="w-[60px] h-[2px] bg-themered block"></span>
                </div>
                {parse(gvfl?.long_description ? gvfl?.long_description : "")}
              </div>
            </div>
            <div className="clear-both"></div>
          </div>
        </div>
      )}

      <div className="clear-both"></div>

      {members !== "" && members !== null && (
        <div className="bg-white float-left w-full overflow-hidden relative md:py-[100px] py-[50px]">
          <div className="container m-auto px-4">
            <div className="float-left w-full pb-10">
              <h2 className="font-Quicksand_Bold text-black md:text-[36px] text-[26px] pb-3 text-center">
                Meet The Team That <br />
                Breathes Life Into A4X
              </h2>
              <span className="w-[60px] h-[2px] bg-themered block m-auto"></span>
            </div>
            <div className="clear-both"></div>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-14 gap-10">
              {members !== "" &&
                members.map((value, index) => (
                  <div
                    key={index}
                    className="float-left w-full text-center grayscale hover:grayscale-0"
                  >
                    <div className="block m-auto w-[150px] h-[150px] border-4 border-[#f8f9fa] text-center rounded-full overflow-hidden mb-4">
                      <img
                        className="w-full h-full object-cover float-left cursor-pointer"
                        src={value?.image_url}
                        alt=""
                        onClick={() => fetchMemberDetails(value)}
                      />
                    </div>
                    <h3
                      className="font-Quicksand_Bold text-[22px] text-[#202020] cursor-pointer"
                      onClick={() => fetchMemberDetails(value)}
                    >
                      {value?.name}
                    </h3>
                    <p
                      className="font-WorkSans_Regular text-base text-[#535353] cursor-pointer"
                      onClick={() => fetchMemberDetails(value)}
                    >
                      {value?.designation}
                    </p>
                  </div>
                ))}
            </div>
            <div className="clear-both"></div>
          </div>
        </div>
      )}

      <div className="clear-both"></div>

      <Modal
        show={show_popup}
        size="5xl"
        position={"top-right"}
        onClose={() => setShowPopup(false)}
        // modalClassName="bg-white p-6 rounded shadow-lg transform transition-transform ease-out duration-300 translate-x-full sm:translate-x-0"
        className="h-[100%] overflow-y-auto"
      >
        <Modal.Header />
        <Modal.Body>
          <div className="justify-content items-center  flex flex-col md:flex-row gap-8 overflow-y-auto h-auto ">
            <div className="flex-col md:flex-col mb-auto top-0 w-[45%]">
              <img
                className=" object-cover float-left "
                src={memberOnModal?.image_url}
                alt=""
              />
            </div>
            <div className="flex-col md:flex-col top-0 mb-auto w-[55%]  ">
              <h1 className="pl-3 text font-Quicksand_Bold text-[30px]">
                {memberOnModal?.name}
              </h1>
              <h2 className="pl-3 pt-5 text-base font-size-18 font-WorkSans_Regular text-[#535353] text-[22px]">
                {memberOnModal?.designation}
              </h2>
              <p className="pl-3  pt-5 float-left w-full font-WorkSans_Medium text-[#202020] text-base leading-7 pb-3 text-[14px] ">
                {memberOnModal?.description
                  ? parse(memberOnModal?.description)
                  : "N/A"}
              </p>

              {memberOnModal?.social_url && (
                <a
                  target="_blank"
                  className="text-[30px] text-center pl-3 pt-4"
                  href={memberOnModal?.social_url}
                >
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              )}
               {memberOnModal?.web_url && (
                <a
                  target='_blank'
                  className={memberOnModal?.social_url ? 'text-[26px] text-center pl-7 pt-4':  'text-[26px] text-center pl-3 pt-4'}
                  rel='noreferrer'
                  href={memberOnModal?.web_url}
                >
                  <i class="fa-solid fa-link"></i>
                </a>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AboutUs;
