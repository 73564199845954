import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as GeneralService from "../../services/general.service";
import parse from "html-react-parser";
const Footer = () => {
  const { t } = useTranslation();
  const [footer, setFooter] = useState("");
  const [social, setSocial] = useState("");

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    const res = await GeneralService.GetContentAPI("get-header-footer-data");
    if (res.response) {
      setFooter(res?.data?.footer);
      setSocial(res?.data?.footer?.social_media);
    } else {
    }
  };

  return (
    <>
      <div className="clear-both"></div>
      <footer className="bg-[#00040e] pb-0 relative overflow-hidden text-white">
        <div className="container m-auto px-4 relative z-[1] lg:border-b lg:border-[#1b1b1b]">
          <div className="clipboard xl:w-5/12 lg:w-auto w-full lg:text-left text-center py-5 float-left">
            <Link to={`/`}>
              <img
                className="w-[120px] lg:m-0 m-auto transition-all"
                src={`${process.env.REACT_APP_FRONT_URL}assets/images/logo_white.svg`}
              />
            </Link>
          </div>
          <div className="xl:w-7/12 lg:w-auto w-full float-right lg:py-6">
            <ul className="lg:float-right">
              <li className="float-left sm:w-auto w-full sm:py-0 py-3 text-[#535353] font-WorkSans_Regular xl:pr-20 pr-10 pl-12 text-base relative">
                <i className="fa-light fa-globe text-white absolute left-0 text-[32px] sm:top-3 top-6"></i>
                Email
                <br />
                <a
                  href={`mailto:${footer?.email}`}
                  className="inline-block text-white text-lg hover:text-[#b3b3b3]"
                >
                  {footer?.email}
                </a>
              </li>
              <li className="float-left sm:w-auto w-full sm:py-0 py-3 text-[#535353] font-WorkSans_Regular pl-12 text-base pr-0 relative">
                <i className="fa-light fa-phone-volume text-white absolute left-0 text-[32px] sm:top-3 top-6"></i>
                Call Us
                <br />
                <a
                  href={`tel:${footer?.phone}`}
                  className="inline-block text-white text-lg hover:text-[#b3b3b3]"
                >
                  {footer?.phone}
                </a>{" "}
                {/* &nbsp;|&nbsp;{" "}
                <a
                  href={`tel:${footer?.second_phone}`}
                  className="inline-block text-white text-lg hover:text-[#b3b3b3]"
                >
                  {footer?.second_phone}
                </a> */}
              </li>
            </ul>
          </div>
          <div className="clear-both"></div>
        </div>

        <div className="wireframe_div w-full float-left relative h-[386px]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d229.50288032023678!2d72.5374268846101!3d23.02208005050511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e853e43d6d329%3A0xd4bb3cc06ab9bedd!2sStratum%20%40%20Venus%20Grounds!5e0!3m2!1sen!2sin!4v1701166479992!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="clipboard1 w-full h-full float-left absolute left-0 top-0 bottom-0"
            style={{ border: 0, zIndex: 3 }}
          ></iframe>
          <div className="clear-both"></div>
        </div>

        <div className="container m-auto px-4 relative z-[1]">
          <div className="lg:w-7/12 w-full xl:pl-3 float-right py-6">
            <div className="float-left">
              <h3 className="text-white font-Quicksand_Medium text-[22px]">
                {footer?.site_name}
              </h3>
              <p className="text-white font-WorkSans_Regular text-base py-3">
                {parse(footer?.address ? footer?.address : "")}
              </p>
              <p className="text-white font-WorkSans_Regular text-base py-6">
                Useful Links&nbsp;:&nbsp;{" "}
                <Link
                  className="inline-block text-[#535353] text-base hover:text-white"
                  to={`/page/terms-conditions`}
                >
                  Terms Of Use
                </Link>{" "}
                &nbsp; | &nbsp;{" "}
                <Link
                  className="inline-block text-[#535353] text-base hover:text-white"
                  to={`/page/privacy-policy`}
                >
                  Privacy Policy
                </Link>{" "}
                &nbsp; | &nbsp;{" "}
                <Link
                  className="inline-block text-[#535353] text-base hover:text-white"
                  to={`/page/credits`}
                >
                  Credits
                </Link>
              </p>
            </div>
            <ul className="sm:float-right sm:w-auto w-full flex sm:flex-col flex-row justify-between gap-2">
              {social !== "" &&
                social.map((value, key) => (
                  <li
                    key={key}
                    className="float-left sm:clear-both text-white text-xl py-2"
                  >
                    <a
                      className="leading-10 block w-[40px] text-center"
                      href={value?.url}
                    >
                      <i className={value?.title}></i>
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          <div className="lg:w-7/12 w-full xl:pl-3 float-right">
            <div className="w-full float-left border-t border-[#1b1b1b] py-6">
              <p className="text-white sm:w-auto w-full sm:text-left text-center font-WorkSans_Regular text-base float-left leading-10">
                ©GVFL Limited&nbsp; | &nbsp;All Rights Reserved
              </p>
              <a href="http://technoinfonet.com/" target={"_blank"}>
                <p className="text-white sm:w-auto w-full sm:text-left text-center font-WorkSans_Regular text-base float-right leading-10">
                  Powerd By{" "}
                  <img
                    className="inline-block h-[40px] ml-1"
                    src={`${process.env.REACT_APP_FRONT_URL}assets/images/techno_logo.svg`}
                    alt=""
                  />
                </p>
              </a>
            </div>
          </div>
          <div className="clear-both"></div>
        </div>
        <h1 class="lg:text-[52px] md:text-[38px] text-[28px] text-[#202020] font-Quicksand_Bold pb-4 lg:leading-[64px] md:leading-[44px] leading-[40px] hidden"></h1>
        <li className="float-left w-full relative font-WorkSans_Medium text-[#535353] md:text-lg text-base py-2 pl-[35px] hidden">
          <i className="fa-solid fa-shield-check text-[24px] text-themeblue absolute left-0 md:top-3.5 top-2.5 hidden"></i>
        </li>
        <p class="float-left w-full text-base text-[#444444] font-WorkSans_Regular pb-5 hidden"></p>
      </footer>
    </>
  );
};

export default Footer;
