import axios from "axios";
import authHeader from "./auth-header";

const END_POINT = `${process.env.REACT_APP_API_URL}`;

// Header & Footer Content
// export const HeaderFooterApi = async () => {
//     var return_data = null;
//     var return_code = 200;
//     var return_response = false;
//     var return_error = null;

//     const data = await axios.get(`${END_POINT}get-header-footer-data`).then((response) => {
//         return_response = true;
//         return_data = response?.data?.data;
//     }).catch((error) => {
//         return_response = false;
//         return_code = error?.code;
//         return_error = error?.message;
//     });

//     return { data: return_data, code: return_code, response: return_response, errors: return_error };
// }

// HomePage Content
// export const HomeDataApi = async () => {
//     var return_data = null;
//     var return_code = 200;
//     var return_response = false;
//     var return_error = null;

//     const data = await axios.get(`${END_POINT}get-home-data`).then((response) => {
//         return_response = true;
//         return_data = response?.data?.data;
//     }).catch((error) => {
//         return_response = false;
//         return_code = error?.code;
//         return_error = error?.message;
//     });

//     return { data: return_data, code: return_code, response: return_response, errors: return_error };
// }

// AboutUs Content
// export const AboutDataApi = async () => {
//     var return_data = null;
//     var return_code = 200;
//     var return_response = false;
//     var return_error = null;

//     const data = await axios.get(`${END_POINT}get-about-us`).then((response) => {
//         return_response = true;
//         return_data = response?.data?.data;
//     }).catch((error) => {
//         return_response = false;
//         return_code = error?.code;
//         return_error = error?.message;
//     });

//     return { data: return_data, code: return_code, response: return_response, errors: return_error };
// }

// Community Content
// export const CommunityDataApi = async () => {
//     var return_data = null;
//     var return_code = 200;
//     var return_response = false;
//     var return_error = null;

//     const data = await axios.get(`${END_POINT}get-community-page-data`).then((response) => {
//         return_response = true;
//         return_data = response?.data?.data;
//     }).catch((error) => {
//         return_response = false;
//         return_code = error?.code;
//         return_error = error?.message;
//     });

//     return { data: return_data, code: return_code, response: return_response, errors: return_error };
// }

// Investor Content
// export const InvestorDataApi = async () => {
//     var return_data = null;
//     var return_code = 200;
//     var return_response = false;
//     var return_error = null;

//     const data = await axios.get(`${END_POINT}get-investor-page-data`).then((response) => {
//         return_response = true;
//         return_data = response?.data?.data;
//     }).catch((error) => {
//         return_response = false;
//         return_code = error?.code;
//         return_error = error?.message;
//     });

//     return { data: return_data, code: return_code, response: return_response, errors: return_error };
// }

// StartUp Content
// export const StartUpDataApi = async () => {
//     var return_data = null;
//     var return_code = 200;
//     var return_response = false;
//     var return_error = null;

//     const data = await axios.get(`${END_POINT}get-startup-page-data`).then((response) => {
//         return_response = true;
//         return_data = response?.data?.data;
//     }).catch((error) => {
//         return_response = false;
//         return_code = error?.code;
//         return_error = error?.message;
//     });

//     return { data: return_data, code: return_code, response: return_response, errors: return_error };
// }

// Event Content
// export const EventDataApi = async () => {
//     var return_data = null;
//     var return_code = 200;
//     var return_response = false;
//     var return_error = null;

//     const data = await axios.get(`${END_POINT}get-event-media-page-data`).then((response) => {
//         return_response = true;
//         return_data = response?.data?.data;
//     }).catch((error) => {
//         return_response = false;
//         return_code = error?.code;
//         return_error = error?.message;
//     });

//     return { data: return_data, code: return_code, response: return_response, errors: return_error };
// }

// Event Content
// export const EventBlogDataApi = async (url) => {
//     var return_data = null;
//     var return_code = 200;
//     var return_response = false;
//     var return_error = null;

//     const data = await axios.get(`${END_POINT}` + url)
//         .then((response) => {
//             return_response = true;
//             return_data = response?.data?.data;
//         }).catch((error) => {
//             return_response = false;
//             return_code = error?.code;
//             return_error = error?.message;
//         });

//     return { data: return_data, code: return_code, response: return_response, errors: return_error };
// }

// Event Content
// export const BlogDataApi = async (url) => {
//     var return_data = null;
//     var return_code = 200;
//     var return_response = false;
//     var return_error = null;

//     const data = await axios.get(`${END_POINT}` + url)
//         .then((response) => {
//             return_response = true;
//             return_data = response?.data?.data;
//         }).catch((error) => {
//             return_response = false;
//             return_code = error?.code;
//             return_error = error?.message;
//         });

//     return { data: return_data, code: return_code, response: return_response, errors: return_error };
// }


// All In One API

export const GetContentAPI = async (url) => {
    var return_data = null;
    var return_code = 200;
    var return_response = false;
    var return_error = null;

    const data = await axios.get(`${END_POINT}` + url)
        .then((response) => {
            return_response = true;
            return_data = response?.data?.data;
        }).catch((error) => {
            return_response = false;
            return_code = error?.code;
            return_error = error?.message;
        });

    return { data: return_data, code: return_code, response: return_response, errors: return_error };
}

export const GetTokenContentAPI = async (url) => {
    var return_data = null;
    var return_code = 200;
    var return_response = false;
    var return_error = null;

    const data = await axios.get(`${END_POINT}` + url, {
        headers: authHeader()
    })
        .then((response) => {
            return_response = true;
            return_data = response?.data?.data;
        }).catch((error) => {
            return_response = false;
            return_code = error?.code;
            return_error = error?.message;
        });

    return { data: return_data, code: return_code, response: return_response, errors: return_error };
}

// Form Post API

export const PostForm = async (url, form) => {
    var return_data = null;
    var return_code = 200;
    var return_response = false;
    var return_error = null;
    var return_status = null;
    var return_message = null;

    const data = await axios.post(`${END_POINT}` + url, form)
        .then((response) => {
            return_response = true;
            return_status = response.data?.status;
            return_data = response?.data?.data;
            return_message = response?.data?.message;
        }).catch((error) => {
            return_response = false;
            return_status = error?.status;
            return_code = error?.code;
            return_error = error?.message;
        });

    return { data: return_data, code: return_code, response: return_response, errors: return_error, status: return_status, message: return_message };
}

// Form Post API

export const PostTokenForm = async (url, form) => {
    var return_data = null;
    var return_code = 200;
    var return_response = false;
    var return_error = null;
    var return_status = null;
    var return_message = null;

    const data = await axios.post(`${END_POINT}` + url, form, {
        headers: authHeader()
    }).then((response) => {
        return_response = true;
        return_status = response.data?.status;
        return_data = response?.data?.data;
        return_message = response?.data?.message;
    }).catch((error) => {
        return_response = false;
        return_status = error?.status;
        return_code = error?.code;
        return_error = error?.message;
    });

    return { data: return_data, code: return_code, response: return_response, errors: return_error, status: return_status, message: return_message };
}